import moment from "moment";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
export default function Output({ data, row, incident }) {
  const [output, setOutput] = useState();
  const [array, setArray] = useState([]);
  useEffect(() => {
    adjustText(data);
  }, []);
  const adjustText = (d) => {
    if (d) {
      let v = d ? d.replaceAll("{sales_data}", "sales data") : "";
      const myText = v;
      const stop = /[.!?]/;
      const sentence = myText.split(stop);

      setOutput(sentence.slice(0, sentence.length - 1));
      // console.log(moment("March 19, 2022").isValid(), row);
    }

    let values =
      row.image_analytics &&
      row.image_analytics.length &&
      row.image_analytics[0].value
        ? row.image_analytics[0].value
        : [];

    setArray(incident.concat(values));
  };
  return output ? (
    <div className="output-painmain">
      <div>
        {output.slice(0, 3).map((o, oi) => {
          return (
            <Highlighter
              key={oi}
              highlightClassName="highlight-txt-output"
              searchWords={array}
              // autoEscape={true}
           
              textToHighlight={`${o}.`}
            />
          );
        })}
      </div>
      {output.length > 3 ? (
        <div>
          {output.slice(3, 6).map((o, oi) => {
            return (
              <Highlighter
                key={oi}
                highlightClassName="highlight-txt-output"
                searchWords={array}
                autoEscape={true}
                textToHighlight={`${o}.`}
              />
            );
          })}
        </div>
      ) : null}
      {output.length > 6 ? (
        <div>
          {output.slice(6, 9).map((o, oi) => {
            return (
              <Highlighter
                key={oi}
                highlightClassName="highlight-txt-output"
                searchWords={array}
                autoEscape={true}
                textToHighlight={`${o}.`}
              />
            );
          })}
        </div>
      ) : null}
      {output.length > 9 ? (
        <div>
          {output.slice(9, 12).map((o, oi) => {
            return (
              <Highlighter
                key={oi}
                highlightClassName="highlight-txt-output"
                searchWords={array}
                autoEscape={true}
                textToHighlight={`${o}.`}
              />
            );
          })}
        </div>
      ) : null}
      {output.length > 12 ? (
        <div>
          {output.slice(12, 15).map((o, oi) => {
            return (
              <Highlighter
                key={oi}
                highlightClassName="highlight-txt-output"
                searchWords={array}
                autoEscape={true}
                textToHighlight={`${o}.`}
              />
            );
          })}
        </div>
      ) : null}
      {output.length > 15 ? (
        <div>
          {output.slice(15, 18).map((o, oi) => {
            return (
              <Highlighter
                key={oi}
                highlightClassName="highlight-txt-output"
                searchWords={array}
                autoEscape={true}
                textToHighlight={`${o}.`}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  ) : null;
}
