import React, { useContext } from "react";
import SearchContext from "../../../Context/SearchContext";
import { Alert, Col, Input, Modal, Row } from "antd";
import { MdClose } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";

export default function ClassifyLabelsArray({ Description, setDescription }) {
  const { generate, setGenerate, assist, setType, type } =
    useContext(SearchContext);

  const changeDescription = (row, key, val) => {
    row[key] = val;
    const all = Description.map((m) => (m.id === row.id ? row : m));
    setDescription(all);
  };

  const deleteDescription = (id) => {
    const all = Description.filter((f) => f.id !== id);
    setDescription(all);
  };

  const closeDescription = () => {
    setGenerate({
      ...generate,
      isLabel: false,
    });
    // setDescriptionId();
  };
  return (
    <div>
      {" "}
      <Modal
        width={520}
        open={generate.isLabel}
        onCancel={() => {
          closeDescription();
        }}
        className="no-f-no-h brand-modal top-20-x"
      >
        <h5 onClick={() => console.log(Description)} className="create-btn-h">
          Label
        </h5>
        <Row>
          <Col span={12} className="">
            {Description &&
              Description.map((b, bi) => {
                return (
                  <table key={bi} className="table-bucket-aws table-b-guide-cm">
                    <tbody>
                      <tr>
                        {/* <td>
                        <b>Label:</b>
                      </td> */}
                        <td>
                          <div className="close-butcke-bx">
                            <MdClose
                              onClick={() => deleteDescription(b.id)}
                              className="close-bx-d-in hover"
                            />
                          </div>
                          <Input
                            onChange={(e) =>
                              changeDescription(b, "label", e.target.value)
                            }
                            value={b.label}
                            placeholder="Label"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            <div className="flex-end">
              <AiOutlinePlus
                onClick={() =>
                  setDescription([
                    ...Description,
                    {
                      content: "",

                      id: Description.length
                        ? Description[Description.length - 1].id + 1
                        : 0,
                    },
                  ])
                }
                className="icn-side-c "
              />
            </div>
            {/* <div>
            {isChangeG ? (
              <Button
                size="sm"
                onClick={() =>
                  saveChanges({ description: Description }, DescriptionId, true)
                }
              >
                Update
              </Button>
            ) : null}
          </div> */}
          </Col>
          <Col span={12}>
            <div className="no-heigh-div-c ">
              <Alert
                message="Autonomous Classifier"
                description="AXV helps you classify your images and videos into specific categories or labels according to what our AI model understands is in them."
                type="info"
                showIcon
                className="brand-alert bg-txt-a"
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
