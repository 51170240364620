import { Input, InputNumber, message } from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
let length = 0;
let oldData = [];
export default function GenerateImage() {
  const { generate, setGenerate, assist, setType, user, setassist } =
    useContext(SearchContext);
  const [search, setSearch] = useState("");
  const [number, setNumber] = useState(3);
  const [loading, setLoading] = useState(false);

  const getDate = () => {
    const obj = { prompt: search, numberOfImages: number };
    const user = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/list/generation/media/image`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => { if (response.status === 400) {
        setLoading(false); // Stop the loading process
        return response.json(); // Return the response data as JSON
      } else
        if (response.message === "Insufficient compute available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        setLoading(false);
        if (response && response.images) {
          setGenerate({
            ...generate,
            imagesGens: response.images,
            selectedIMG: undefined,
            selectedVID: undefined,
            clips: undefined,
          });
          setType("Generate");
          //   setAsyncModal(asyncModal ? asyncModal : "this is date selection");
        }
      });
  };
  const genImgs = () => {
    const r1 = generate.selectedIMG
      ? "image"
      : generate.selectedVID
      ? "video"
      : undefined;
    if (r1 === undefined) {
      message.warning("Please select a file");
      setLoading(false);
      return;
    }

    oldData = [];
    length = 1;
    if (generate.selectedIMG && generate.selectedIMG.length) {
      for (let i = 0; i < generate.selectedIMG.length; i++) {
        getIMG(
          generate.selectedIMG[i],
          generate.selectedIMG.length,
          "selectedIMG"
        );
      }
    } else if (generate.selectedVID && generate.selectedVID.length) {
      for (let i = 0; i < generate.selectedVID.length; i++) {
        getIMG(
          generate.selectedVID[i],
          generate.selectedVID.length,
          "selectedVID"
        );
      }
    }
  };

  const getIMG = (row, l) => {
    const r1 = generate.selectedIMG
      ? "image"
      : generate.selectedVID
      ? "video"
      : "";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify({}),
    };
    fetch(`https://int.display.video/api/list/generation/create/${r1}/description/${row._id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.message === "Insufficient compute available") {
        setassist({
          ...assist,
          isUpgrade: true,
        });
      } else if (data.message === "400") {
        setLoading(false);
      }
  
      if (data && data.images && data.images.length > 0) {
        let generatedImages = data.images.map((image) => {
          return {
            _id: row._id,
            file_url: row.file_url, // Use the file_url from the existing 'row' object
            generated: data.images[0], // Assign the 'generated' value from the API response
            // Add any additional properties from the response if needed
          };
        });
  
        let updatedRow = { ...row, generated: data.images[0] };
  
        console.log("Updated Row:", updatedRow);
        console.log("Generated Images:", generatedImages);
  
        if (length === l) {
          setGenerate({
            ...generate,
            imagesGens: [...oldData, updatedRow],
            selectedIMG: undefined,
            selectedVID: undefined,
            clips: undefined,
          });
  
          setLoading(false);
          setType("Generate");
        } else {
          oldData = [...oldData, updatedRow];
          length = length + 1;
        }
      } else {
        if (length === l) {
          setGenerate({
            ...generate,
            imagesGens: oldData,
            selectedIMG: undefined,
            selectedVID: undefined,
            clips: undefined,
          });
  
          setLoading(false);
          setType("Generate");
        }
        length = length + 1;
      }
    });
  
  

  };
  return (
    <div onClick={() => console.log(generate)} className="gen-img-mbc">
      {loading ? (
        <div className="cen-gen-bximg">
          <div class="loader32"></div>
        </div>
      ) : (
        <div>
          {generate.activeType === "custom_image" ? (
            <div className="gen-img-bncs">
              <Input
                value={search}
                placeholder="Text"
                className="gen-mom-input"
                onChange={(e) => setSearch(e.target.value)}
              />
              <InputNumber
                className="gen-mom-input number0nput"
                min={1}
                max={2}
                value={number}
                onChange={(v) => setNumber(v)}
              />
            </div>
          ) : null}
          <div className="cen-gen-bximg">
            <button
              onClick={() => {
                setLoading(true);
                if (generate.activeType === "custom_image") {
                  getDate();
                } else {
                  genImgs();
                }
              }}
              size="small"
              className="glow-on-hover"
            >
              Generate
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
