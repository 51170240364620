import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import SearchContext from "../../../Context/SearchContext";
import { AiOutlineBgColors, AiOutlineUpload } from "react-icons/ai";
import {
  MdClose,
  MdMenuBook,
  MdOutlineCode,
  MdHighlightOff,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import { BiSolidVideos } from "react-icons/bi";
import { FaVideoSlash } from "react-icons/fa";
import { Switch, Tooltip } from "antd";
import {
  BsDatabaseCheck,
  BsFillCassetteFill,
  BsFillCloudUploadFill,
  BsGlobe,
} from "react-icons/bs";
import UploadAssets from "./UploadAssets";
import { RiAccountBoxFill, RiPhoneLine } from "react-icons/ri";
import Call from "./Call";
import GeneratedVideos from "./GeneratedVideos";

export default function ThemeSelection() {
  const {
    type,
    setTheme,
    left,
    setLeft,
    setRight,
    right,
    setFormType,
    user,
    setDisplay,
  } = useContext(SearchContext);
  const [display, setDispla1] = useState(false);
  const [open, setOpen] = useState(false);

  const [colors, setColors] = useState([
    { n: "black", c: " #171717" },
    { n: "grey", c: "grey" },
    { n: "brown", c: "#C4A484" },
    { n: "purple", c: "#E39FF6" },
    { n: "green", c: "#F0FFF0" },
    { n: "blue", c: "#E3F2FD" },
    { n: "white", c: "#F6EEE3" },
  ]);
  return (
    <div className="theme-main-divs theme-main-divs21">
      {display ? (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="colors-bx-main-d"
        >
          {colors.map((c, ci) => {
            return (
              <div
                onClick={() => {
                  setTheme(c.n);
                  window.localStorage.setItem("theme", c.n);
                }}
                style={{ backgroundColor: c.c }}
                key={ci}
              />
            );
          })}{" "}
          <MdClose onClick={() => setDispla1(false)} />
        </motion.div>
      ) : (
        <div>
          <GeneratedVideos />
          <Tooltip
            color="white"
            title="Enable Olm to use your uploaded images and videos"
          >
            <b
              style={{
                display: "flex",
                alignItems: "center", // Align items vertically in flex container
                gap: "8px", // Add spacing between icon and text
              }}
              onClick={() =>
                setRight({ ...right, isStorage: !right.isStorage })
              }
            >
              {right.isStorage ? (
                <MdOutlineCheckCircleOutline />
              ) : (
                <MdHighlightOff />
              )}
              {right.isStorage ? "Assets" : "Assets"}
            </b>
          </Tooltip>

          {/* <Tooltip
            color="white"
            title="Enable Olm with visual data from the web"
          >
            <b
              style={{
                display: "flex",
                alignItems: "center", // Align items vertically in flex container
                gap: "8px", // Add spacing between icon and text
              }}
              onClick={() => setRight({ ...right, isWeb: !right.isWeb })}
            >
              {right.isWeb ? (
                <MdOutlineCheckCircleOutline />
              ) : (
                <MdHighlightOff />
              )}
              {right.isWeb ? "Web" : "Web"}
            </b>
          </Tooltip> */}

          {/* <Tooltip color="white" title="API Docs">
  <b>
    <a
      href="https://docs.olm.ai/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <MdMenuBook />
      <span className="hide-mobile" style={{ marginLeft: "4px" }}>Guide</span>
    </a>
  </b>
</Tooltip> */}

          {right.upOpen ? (
            <UploadAssets setOpen={setOpen} type2={type} />
          ) : (
            <Tooltip color="white" title="Upload">
              <b
                onClick={() => {
                  if (user && user.name) {
                    setRight({ ...right, upOpen: true });
                  } else {
                    setFormType("signup");
                  }
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <span className="hide-mobile">Upload</span>
                </div>
              </b>
            </Tooltip>
          )}
          {right.upOpen ? null : (
            <Tooltip color="white" title="Assets">
              <b
                onClick={() => {
                  if (user && user.name) {
                    setLeft({
                      ...left,
                      flow: "assets",
                    });
                  } else {
                    setFormType("signup");
                  }
                }}
              >
                <BsDatabaseCheck />
              </b>
            </Tooltip>
          )}
          {/* {right.upOpen ? null : (
            <Tooltip color="white" title="Theme">
              <b onClick={() => setDispla1(true)}>
                <AiOutlineBgColors />
              </b>
            </Tooltip>
          )} */}

          {/* <Tooltip color="white" title="API Docs">
  <b>
    <a
      href="https://docs.olm.ai/api-reference"
      target="_blank"
      rel="noopener noreferrer"
    >
      <MdOutlineCode />
      <span className="hide-mobile" style={{ marginLeft: "4px" }}>API</span>
    </a>
  </b>
</Tooltip> */}

          {/* 
<Tooltip color="white" title="Call">
  <b onClick={() => setLeft({...left , call: true})}>
    <RiPhoneLine  />
  </b>
</Tooltip> 
*/}

          {user && user.name ? (
            <Tooltip color="white" title="Your Account Details">
              <b onClick={() => setDisplay("account")}>
                <RiAccountBoxFill />
              </b>
            </Tooltip>
          ) : null}
        </div>
      )}
    </div>
  );
}
