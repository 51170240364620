import React, { useState, useContext, useEffect } from "react";
import Chat from "./components/Chat";
import "./newhome.css";
import "./theme.css";
import SearchContext from "../../Context/SearchContext";
import SearchThubnail from "./components/flow/SearchThubnail";
import { Col, Drawer, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineClose } from "react-icons/ai";
import GeneratedLive from "./components/flow/GeneratedLive";
import WebGenerated from "./components/flow/WebGenerate";
import Header from "../../components/Header";
import ThemeSelection from "./components/ThemeSelection";
import Assests from "./components/flow/Assests";
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from "react-icons/bs";
import Call from "./components/Call";
import { isMobile } from "react-device-detect";
import Onboard from "./components/onboard/Onboard";

export default function NewHome() {
  const {
    setUser,
    loginChange,
    left,
    setLeft,
    theme,
    setTheme,
    setOrganization,
    setLanguage,
  } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    let obj;
    if (u) {
      obj = u;
      setUser(u);
      getOrganization(u)
    } else {
      let isID = JSON.parse(window.localStorage.getItem("isID"));
      let user_id;
      if (isID) {
        user_id = isID.user_id;
      } else {
        user_id = uuidv4();
        // window.localStorage.setItem("isID", JSON.stringify({ user_id }));
      }
      obj = { user_id };
      setUser(obj);
    }
    setLoading(false);
  }, [loginChange]);

   useEffect(() => {
    const t = window.localStorage.getItem("theme");
    const lang = window.localStorage.getItem("language");
    if (lang) {
      setLanguage(lang);
    }
    if (t) {
      setTheme(t);
    }
  }, []);

  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/organization/${u.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
        }
      });
  };

  return !loading ? (
    <div  className={`main-bx-2fc-m ${theme}-theme`}>
      {/* <Onboard /> onboard temp stop */}
      <Header />
      {left.call ? <Call /> : null}
      <Row>
        <Col
          className={isMobile ? "hide-mobile" : ""}
          style={{ transition: "all 0.7s ease" }}
          offset={left.flow ? (isMobile ? 0 : 1) : 0}
          span={left.flow ? 10 : isMobile ? 0 : 1}
        >
          {" "}
          {left.flow ? (
            <div className="main-bx-2fc-d">
              <div className="close-bx-cht">
                <BsFillArrowLeftSquareFill
                  onClick={() => {
                    setLeft({});
                  }}
                />
              </div>
              {left.flow === "search" ? <SearchThubnail /> : null}
              {left.flow === "generate" ? <GeneratedLive /> : null}
              {left.flow === "web_search" ? <WebGenerated /> : null}
              {left.flow === "assets" ? <Assests /> : null}
            </div>
          ) : null}
        </Col>
        <Col
          style={{ transition: "all 0.7s ease" }}
          span={isMobile ? 24 : left.flow ? 13 : 22}
        >
          <div className="main-bx-2fc-d">
            <Chat />
          </div>
        </Col>
        <Col
          s
          style={{ transition: "all 0.7s ease" }}
          span={isMobile ? 0 : left.flow ? 0 : 1}
        >
          {" "}
        </Col>
      </Row>
      <div >
        <Drawer
          title="Media"
          placement="right"
        
          onClose={() => setLeft({ ...left, flow: undefined })}
          open={left.flow && isMobile}
          className={`${theme}-theme`}
        >
          <div className="main-bx-2fc-d">
            <div style={{opacity:1}} className="close-bx-cht">
              <BsFillArrowRightSquareFill
                onClick={() => {
                  setLeft({});
                }}
              />
            </div>
            {left.flow === "search" ? <SearchThubnail /> : null}
            {left.flow === "generate" ? <GeneratedLive /> : null}
            {left.flow === "web_search" ? <WebGenerated /> : null}
            {left.flow === "assets" ? <Assests /> : null}
          </div>
        </Drawer>
      </div>

      <ThemeSelection />
    </div>
  ) : null;
}
