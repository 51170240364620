import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  message,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaDownload, FaEdit, FaLaptop, FaMinus } from "react-icons/fa";
import Output from "./Output";
import { BiCheckDouble } from "react-icons/bi";
import Avatar from "react-avatar";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { RiImageEditFill, RiSendPlaneFill } from "react-icons/ri";
import { SiHey } from "react-icons/si";
import {
  FaInfoCircle,
  FaExclamationTriangle,
  FaTimesCircle,
} from "react-icons/fa";
import ReactPlayer from "react-player";
import {
  MdOutlineCreate,
  MdOutlineImageSearch,
  MdOutlineTravelExplor,
  MdInsights,
  MdClose,
} from "react-icons/md";
import SearchContext from "../../../Context/SearchContext";
import UpgradeModal from "../../home/components/UpgradeModal";
import {
  BsFillDatabaseFill,
  BsGlobe,
  BsMagic,
  BsCollectionPlayFill,
} from "react-icons/bs";
import { motion } from "framer-motion";
import { HiOutlineDocumentSearch } from "react-icons/hi";
export default function Chat() {
  const {
    assist,
    organization,
    setassist,
    chatId,
    user,
    load,
    setLoad,
    callApi,
    setCallApi,
    language,
    setFormType,
    incident,
    setincident,
    right,
    left,
    setLeft,
    pActive,
    loginChange,
    setDisplay,
  } = useContext(SearchContext);

  const [open, setOpen] = useState(false);
  const [d, setD] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [callS, setCallScrool] = useState(1);

  useEffect(() => {
    if (
      (assist.pageNo === 1 && assist.data) ||
      (user && user.name === undefined)
    ) {
      setLoading2(false);
    } else {
      getVideoHigh();
    }
  }, [callApi, chatId, loginChange]);

  useEffect(() => {
    let v = [];
    if (incident === undefined) {
      for (let i = 0; i < 1000; i++) {
        v.push(`${i} incident`);
      }
      setincident(v);
    }
  }, []);

  useEffect(() => {
    if (open || load) {
      scrolBottom();
    }
  }, [open, load]);

  useEffect(() => {
    if (assist.data) {
      scrolBottom();
    }
  }, [callS]);

  const [comentNew, setComentNew] = useState("");

  const handleChange = (e) => {
    setComentNew(e.target.value);
  };
  const getVideoHigh = (h) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/chat_log?page=${
        assist.pageNo
      }&limit=15&user_id=${
        user ? user.user_id : "map"
      }&sort_by=${-1}&assistant_id=${
        pActive ? pActive._id : "645f00b8744d5ff6bc8571e8"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setLoading2(false);
        if (data && data.isSuccess) {
          setassist({
            ...assist,
            data: data.chatlogList
              .reverse()
              .concat(assist.data ? assist.data : []),
            totalPages: data.totalPages,
          });
          if (callApi === 1) {
            setTimeout(() => scrolBottom(), 1000);
          }
        }
      });
  };
  const findTimeStamp = (q) => {
    let a = q;
    const i = a.indexOf(":");
    if (i !== -1 && a.slice(i + 3, i + 4) === ":") {
      return a.slice(i - 2, i + 6);
    }
  };

  const scrolBottom = () => {
    const element = document.getElementsByClassName("active-tab-com-dfc22");
    if (element && element.length) {
      setTimeout(() => {
        element[0].scroll({
          top: element[0].scrollHeight,
          behavior: "smooth",
        });
      }, 300);
    }
  };
  const loadMore = (v) => {
    // console.log(v.target.scrollHeight, v.target.scrollTop);
    if (
      v.target.scrollTop === 0 &&
      assist.totalPages > assist.pageNo &&
      !loading
    ) {
      setassist({
        ...assist,
        pageNo: assist.pageNo + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi(callApi + 1);
    }
  };

  const updateFiledChat = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/chat_log/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const all = assist.data.map((m) =>
            m._id === data.chatlogData._id ? data.chatlogData : m
          );
          setassist({
            ...assist,
            data: all,
          });
        } else {
          message.warning("Something Went Wrong");
        }
      });
  };

  const updateFiled = (d) => {
    const obj = {
      chat_id: d._id,
      approve: true,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/v3/chapter/approve_action`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          d.is_approved = true;
          const all = assist.data.map((m) => (m._id === d._id ? d : m));
          setassist({
            ...assist,
            data: all,
          });
        } else {
          message.warning("Something Went Wrong");
        }
      });
  };
  const handleSubmit = (p) => {
    // if (comentNew === "") {
    //   return;
    // }
    const c = p ? p : comentNew;

    const txt = c.trim().length ? c.trim() : assist.upImage ? "Image Sent" : "";
    setLoad(txt);
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const obj = {
      query: txt,
      user_id: user ? user.user_id : "map",
      web_search: right.isWeb,
      storage: right.isStorage,
      // assistant_id: "645f00b8744d5ff6bc8571e8",
      assistant_id: pActive ? pActive._id : "645f00b8744d5ff6bc8571e8",
      language,
      edit: assist.editImg,
      video_id: assist.selAssetID2 ? assist.selAssetID2._id : undefined,
      startTime: assist.selAssetID2 ? findTimeStamp(txt) : undefined,
      image_id: assist.upImage
        ? assist.upImage[0].purpose === "misc"
          ? assist.upImage[0]._id
          : assist.upImage[0].headclip_id
        : assist.selAssetID
        ? assist.selAssetID.purpose === "misc"
          ? assist.selAssetID._id
          : assist.selAssetID.headclip_id
        : undefined,
      media: assist.selAssetID2
        ? true
        : assist.upImage
        ? true
        : assist.selAssetID
        ? true
        : assist.chatMedia
        ? true
        : right.activeRight === "Search"
        ? true
        : right.activeRight === "Analytics"
        ? true
        : undefined,
      insights: right.activeRight === "Analytics" ? true : undefined,
    };
    setComentNew("");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/v3/chapter/chatv2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoad();
        if (data.message === "Insufficient compute available") {
          setDisplay("Pricing");
        }
        if (data && data.isSuccess) {
          const all = assist.data ? assist.data : [];
          let d = data.data;
          d.response = data.response ? data.response : undefined;
          d.is_approval = data.is_approval ? data.is_approval : undefined;
          d.isProduct = data.isProduct ? data.isProduct : false;
          d._id = data.chat_id ? data.chat_id : undefined;
          d.flow =
            data.flow === "general" ||
            data.flow === "create_image" ||
            data.flow === "newImages" ||
            data.flow === "create_video"
              ? undefined
              : data.flow;
          d.generatedLive = data.generatedLive;
          d.newImages = data.newImages;
          d.newVideo = data.newVideo;

          all.push(d);
          setassist({
            ...assist,
            idClip: chatId ? data.data : undefined,
            data: all,
            animation: true,
            searchImages:
              d.clip_data && d.clip_data.length
                ? d.clip_data
                : assist.searchImages,
            editImg: undefined,
          });

          setLeft({
            ...left,
            flow:
              data.flow === "general" ||
              data.flow === "create_image" ||
              data.flow === "newImages" ||
              data.flow === "create_video"
                ? left.flow
                : data.flow,
            chatData: d,
          });

          setTimeout(() => {
            setCallScrool(callS + 1);
          }, 500);
        } else if (data.message === "Insufficient compute available") {
          setDisplay("Pricing");
        } else {
          if (
            data.message ===
            "Chat limit has been reached, please create an accountt"
          ) {
            // setFormType("signup");
          } else if (
            data.message === "Please upgrade account. Query limit reached"
          ) {
            if (user && user.name === undefined) {
              setFormType("signup");
            }
            setassist({
              ...assist,
              isUpgrade: true,
            });
          } else {
            message.error(
              "Sorry, i had some internal system issues, send your message again"
            );
          }
        }
      });
  };
  return (
    <div className={` chat-bx-mn  `}>
      <UpgradeModal />

      {assist.selAssetID2Load ||
      assist.selAssetID2 ||
      assist.upImage ||
      assist.selAssetID ? (
        //    && onboard temp stop
        // (organization &&
        // organization.onboard === "done" || organization === undefined)
        <div className="img-active-popcs">
          <div className="img-active-popcs2">
            {assist.selAssetID2Load ? (
              <div className="sel-img-id-ass">
                <Card className="card-bx-fl">
                  <div className="vd-nm-pr">
                    <ReactPlayer
                      url={assist.selAssetID2Load.file_url}
                      controls={false}
                    />
                  </div>
                  <div className="play-btn-vdc ">
                    <Spin size="large" className="brand-icon " />
                  </div>
                </Card>
              </div>
            ) : null}
            {assist.selAssetID2 ? (
              <div className="sel-img-id-ass">
                <div className="vd-nm-pr">
                  <ReactPlayer
                    url={assist.selAssetID2.file_url}
                    controls={false}
                  />
                </div>
                <GrFormClose
                  onClick={() =>
                    setassist({
                      ...assist,

                      selAssetID2: undefined,
                    })
                  }
                />
              </div>
            ) : null}
            {assist.upImage ? (
              <div className="sel-img-id-ass">
                <img className="" src={assist.upImage[0].file_url} alt="" />
                <GrFormClose
                  style={{ color: "white" }}
                  onClick={() =>
                    setassist({
                      ...assist,

                      upImage: undefined,
                    })
                  }
                />
              </div>
            ) : null}
            {assist.selAssetID ? (
              <div className="sel-img-id-ass">
                <img className="" src={assist.selAssetID.file_url} alt="" />
                <AiOutlineClose
                  style={{ color: "white" }}
                  onClick={() =>
                    setassist({
                      ...assist,

                      selAssetID: undefined,
                    })
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      <div
        onScroll={(v) => {
          loadMore(v);
        }}
        className="active-tab-com-dfc22"
      >
        {loading2 ? (
          <div className="load-chat-ccs">
            <Spin />
          </div>
        ) : null}
        {(assist.data && assist.data.length === 0 && load === undefined) ||
        (!loading2 && assist.data === undefined && load === undefined) ? (
          <div className="no-heigh-div-c full-h-bx-a">
            <h5 className="trust-h-mn">
              Trusted by 8000 users as a visual copilot
            </h5>
            <div className="row">
              <div className="col-sm-6">
                <Alert
                  message={
                    <span style={{ fontWeight: "bold" }}>Welcome To Olm</span>
                  }
                  description={
                    <div style={{ fontFamily: "Dancing Script" }}>
                      <div>
                        An AI model optimized for your images and videos.
                      </div>
                    </div>
                  }
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-a mb-3"
                  icon={<SiHey />}
                />

                <Alert
                  message={<span style={{ fontWeight: "bold" }}>Search</span>}
                  description="Search and chat about your video assets with ease"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-b mb-3"
                  icon={<MdOutlineImageSearch />}
                />

                <Alert
                  message={<span style={{ fontWeight: "bold" }}>Generate</span>}
                  description="Leverage your visual data to generate new content."
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-c mb-3"
                  icon={<MdOutlineCreate />}
                />
              </div>

              <div className="col-sm-6">
                <Alert
                  message={<span style={{ fontWeight: "bold" }}>Create</span>}
                  description="Create new videos from scratch"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-c mb-3"
                  icon={<BsMagic />}
                />

                <Alert
                  message={<span style={{ fontWeight: "bold" }}>Analyze</span>}
                  description="Analyze and pull insights from your visual assets"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-d mb-3"
                  icon={<MdInsights />}
                />

                <Alert
                  message={
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      Try This{" "}
                    </span>
                  }
                  description="Create a video of fish underwater in the ocean "
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-d mb-3"
                  icon={<BsCollectionPlayFill />}
                />
              </div>
            </div>
            {/* <div className="no-chat-cards">
  <h3 onClick={() => handleSubmit("How do you use my assets?")}>
    <Alert
      message={
        <span style={{ fontWeight: "bold" }}>
          How do you use my assets?
        </span>
      }
      type="info"
      showIcon={false}
      className="brand-alert bg-txt-d mb-3"
    />
  </h3>

  <h3
    onClick={() =>
      handleSubmit("Create a image of the golden gate bridge")
    }
  >
    <Alert
      message={
        <span style={{ fontWeight: "bold" }}>
          Create a image of the golden gate bridge
        </span>
      }
      type="info"
      showIcon={false}
      className="brand-alert bg-txt-d mb-3"
    />
  </h3>
  <h3
    onClick={() =>
      handleSubmit("search the web for sleeping habits good for me")
    }
  >
    <Alert
      message={
        <span style={{ fontWeight: "bold" }}>
          Search the web for sleeping habits good for me
        </span>
      }
      type="info"
      showIcon={false}
      className="brand-alert bg-txt-d mb-3"
    />
  </h3>
</div> */}
          </div>
        ) : null}
        {assist.data &&
          assist.data.map((cm, cmi) => {
            return (
              <div
                style={{
                  transition: "0.4s",
                }}
                className="assit-dat-cs"
                key={cmi}
              >
                <div
                  className="chat-out-p1"
                  style={{ fontFamily: "Inter", fontSize: 17 }} //this controls font size for users message
                >
                  <div className="">
                    <span>{cm.query}</span>
                    {cm.image_url && cm.image_url.length ? (
                      <div className="img-up-qur">
                        {cm.image_url.map((im, imi) => {
                          return <img src={im.file_url} alt="" />;
                        })}
                      </div>
                    ) : null}
                    <div className="dbl-tci-dvc">
                      <BiCheckDouble className="double-tick-brand" />
                    </div>
                  </div>
                </div>

                <div className="chat-out-p12">
                  <div
                    className={` ${assist.activeClips ? " clips-m-size" : ""}`}
                    style={{
                      marginBottom:
                        cmi + 1 === assist.data.length ? "0px" : "10px",
                    }}
                    onClick={() => setD(cm._id)}
                  >
                    <div
                      className="flex-bxos"
                      style={{ fontFamily: "Inter", fontSize: 17 }} //This controls sizing for AI message
                    >
                      {" "}
                      <div
                        onClick={() => console.log(cm)}
                        className="ai-chat-txt-c "
                      >
                        <div className="theme-main-divs no-no">
                          <div>
                            <b>
                              {cm.flow ? (
                                cm.flow === "generate" ? (
                                  <MdOutlineCreate
                                    onClick={() => {
                                      setLeft({
                                        ...left,
                                        flow: cm.flow,
                                        chatData: cm,
                                      });
                                    }}
                                  />
                                ) : cm.flow === "search" ? (
                                  <HiOutlineDocumentSearch
                                    onClick={() => {
                                      setLeft({
                                        ...left,
                                        flow: cm.flow,
                                        chatData: cm,
                                      });
                                    }}
                                  />
                                ) : cm.flow === "web_search" ? (
                                  <BsGlobe
                                    onClick={() => {
                                      setLeft({
                                        ...left,
                                        flow: cm.flow,
                                        chatData: cm,
                                      });
                                    }}
                                  />
                                ) : (
                                  <span />
                                )
                              ) : (
                                <span />
                              )}
                            </b>
                          </div>
                        </div>
                        <span>
                          <Output
                            t={assist.data.length}
                            l={cmi}
                            data={cm.output}
                            row={cm}
                          />
                        </span>{" "}
                      </div>
                    </div>
                    {cm.response || cm.is_approval || cm.is_approved ? (
                      <div className="chat-resp-c1">
                        <div className="">
                          <p>{cm.response}</p>
                        </div>

                        <span
                          onClick={() => {
                            if (cm.is_approved) {
                            } else {
                              updateFiled(cm);
                            }
                          }}
                          className={`btn-appr ${
                            cm.is_approved ? " btn-apr-s" : ""
                          }`}
                        >
                          {cm.is_approved ? "Action Taken" : "Approve"}
                        </span>
                      </div>
                    ) : null}
                    <div className="imgs-bx-crts">
                      <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: assist.animation ? 0.7 : 0,
                          delay: assist.animation ? 1 : 0,
                        }}
                        style={{ width: "100%" }}
                      >
                        {cm.newImages &&
                          cm.newImages.map((cim, cii) => {
                            return (
                              <div
                                className={`${
                                  assist.editImg === cim ? " sel-edit-img" : ""
                                }`}
                              >
                                <img src={cim} alt="" key={cii} />
                                <div className="img-edit-d-icons">
                                  <Tooltip color="white" title="Edit Image">
                                    {assist.editImg === cim ? (
                                      <MdClose
                                        onClick={() => {
                                          setassist({
                                            ...assist,
                                            editImg: undefined,
                                          });
                                        }}
                                      />
                                    ) : (
                                      <RiImageEditFill
                                        onClick={() => {
                                          setassist({
                                            ...assist,
                                            editImg: cim,
                                          });
                                        }}
                                      />
                                    )}
                                  </Tooltip>
                                  <a download href={cim}>
                                    {" "}
                                    <FaDownload />
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                        {cm.newVideo ? (
                          <div
                            className={`  ${
                              assist.editImg === cm.newVideo
                                ? " sel-edit-img"
                                : ""
                            }`}
                            style={{ paddingLeft: "5px" }}
                          >
                            <div className={`vd-nm-pr  `}>
                              <ReactPlayer url={cm.newVideo} controls />
                            </div>
                            <div
                              style={{ marginTop: "10px" }}
                              className="img-edit-d-icons"
                            >
                              <Tooltip color="white" title="Edit Image">
                                {assist.editImg === cm.newVideo ? (
                                  <MdClose
                                    onClick={() => {
                                      setassist({
                                        ...assist,
                                        editImg: undefined,
                                      });
                                    }}
                                  />
                                ) : (
                                  <RiImageEditFill
                                    onClick={() => {
                                      setassist({
                                        ...assist,
                                        editImg: cm.newVideo,
                                      });
                                    }}
                                  />
                                )}
                              </Tooltip>
                              <a download href={cm.newVideo}>
                                {" "}
                                <FaDownload />
                              </a>
                            </div>
                          </div>
                        ) : null}
                      </motion.div>
                    </div>
                    <div className="date-tm-chat flex-bxos">
                      <div></div>
                      <span>
                        {moment(cm.createdAt).format("lll")}
                        {/* {cm.isEdit ? <Create row={cm} /> : <span />} */}
                      </span>
                    </div>
                  </div>
                </div>

                {/* {isSources ? (
                  <>
                    {(cm.analytics && cm.analytics.length) ||
                    (cm.trend_data && cm.trend_data.length) ? (
                      <div className="chat-main-top-ch">
                        <ChartsArea data={cm} />
                      </div>
                    ) : cm.clip_data && cm.clip_data.length ? (
                      <div className="chat-main-top-ch">
                        <MomentsChat title={"Moments"} data={cm} />
                      </div>
                    ) : null}
                    {cm.image_analytics && cm.image_analytics.length ? (
                      <ImageAnalyticsChart data={cm} />
                    ) : null}

                    {cm.isProduct ? <Products data={cm} /> : null}

                    {cm.map_load && cm.map_load.length ? (
                      <MapChat data={cm} />
                    ) : null}
                  </>
                ) : null} */}

                {cm.isCreated ? (
                  <p style={{ flexDirection: "row" }} className="chat-out-p">
                    <b>
                      <FaLaptop className="o-icn-dcp" />
                    </b>
                    <span>
                      <div className="typing-loader"></div>
                    </span>
                  </p>
                ) : null}
              </div>
            );
          })}
        {load ? (
          <div style={{ marginTop: "10px" }}>
            <div className="chat-out-p1">
              <p style={{ display: "flex", flexDirection: "row" }} className="">
                <span>
                  {load} <BiCheckDouble className="double-tick-b" />
                </span>
              </p>
            </div>
            <div className="chat-out-p12">
              <p style={{ flexDirection: "row", width: "70px" }} className="">
                <span>
                  <div className="typing-loader"></div>
                </span>
              </p>
            </div>
          </div>
        ) : null}
        {/* </>
  )} */}
      </div>

      <div className="bottom-chat-c">
        <div className="main-com-bx-1">
        <div className="InputContainer">
          {user && user.name === undefined ? (
            // Show the signup form if the user is not logged in or has no name
            <Form
              onFinish={() => {
                setFormType("signup"); // Call setFormType with "signup"
              }}
              style={{ marginBottom: "0px", width: "100%" }}
            >
              <Form.Item
                style={{ marginBottom: "0px" }}
                className="create-cmt-vdc21"
              >
                <Input
                  style={{
                    marginBottom: "0px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  placeholder="Write a message"
                  onChange={handleChange}
                  value={comentNew}
                  size="large"
                />
                <div className="icns-icns-st-rt">
                  {load ? (
                    <Spin />
                  ) : (
                    <RiSendPlaneFill
                      onClick={() => {
                        setFormType("signup"); // Call setFormType with "signup"
                      }}
                    />
                  )}
                </div>
              </Form.Item>
            </Form>
          // ) : assist.data && assist.data.length ? ( //onboard temp stop
            ) :  (
            // Show the regular form if the user is logged in
            <Form
              onFinish={() => {
                handleSubmit();
              }}
              style={{ marginBottom: "0px", width: "100%" }}
            >
              <Form.Item
                style={{ marginBottom: "0px" }}
                className="create-cmt-vdc21"
              >
                <Input
                  style={{
                    marginBottom: "0px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  placeholder="Write a message"
                  onChange={handleChange}
                  value={comentNew}
                  size="large"
                />
                <div className="icns-icns-st-rt">
                  {load ? (
                    <Spin />
                  ) : (
                    <RiSendPlaneFill
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  )}
                </div>
              </Form.Item>
            </Form>
          ) }
          {/* ) : null} onboard temp stop*/}
        </div>
        </div>
      </div>
    </div>
  );
}
