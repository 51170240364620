import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  message,
  notification,
  Modal,
  Spin,
} from "antd";

import { Link, useNavigate } from "react-router-dom";
import { AiFillMail } from "react-icons/ai";
import bcrypt from "bcryptjs";
import Header from "../../components/Header";
import SearchContext from "../../Context/SearchContext";
import GreenTick from "../../components/GreenTick";
import ReactPlayer from "react-player";
import video from "../../images/Mountains_34d.mp4";
import ForgetPassword from "./ForgetPassword";
export default function Login() {
  const {
    setLoading,
    setUser,
    user,
    loginChange,
    setLoginChange,
    resetAll,
    setFormType,
    setOrganization,
    formType,
  } = useContext(SearchContext);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [verifyD, setVerifyD] = useState(false);
  const [res, setRes] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [vError, setVError] = useState();
  const [u, setU] = useState();
  const [loadingS, setloadingS] = useState();

  useEffect(() => {
    if (user && user.name) {
      navigate("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setloadingS(true);
    addUserLogin(form);
  };

  const addUserLogin = (user) => {
    const pass = bcrypt.hashSync(
      user.password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    const obj = {
      password: pass,
      email: user.email,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/consumer/auth/login`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        // if (response && response.isLogged && response.email_verified) {
        if (response && response.isLogged) {
          window.localStorage.setItem("user", JSON.stringify(response));
          setForm({
            email: "",
            password: "",
          });
          navigate("/");
          setFormType();
          setLoading(false);
          setUser(response);
          resetAll();
          // setPActive();
          setLoginChange(!loginChange);
          getOrganization(response);

          // } else if (!response.email_verified && response.isLogged) {
          //   setVerifyD(true);
          //   setU(response);
        } else {
          notification.error({
            message: "Error",
            description: response.msgError,
          });
        }
        setloadingS(false);
      });
  };

  const changeField = (key, val, row) => {
    // console.log(key, val, row);
    row[key] = val;
    setForm({ ...form, [key]: val });
  };

  const resentCode = () => {
    const obj = {
      email: form.email,
      verification_for: "new_registration",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/verification/resend`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setRes(true);
        }
      });
  };

  const verifyEmail = () => {
    const obj = {
      code: Number(verifyCode),
      email: form.email,
      verification_for: "new_registration",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/verification/verify`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let usr = u;
          usr.email_verified = true;
          window.localStorage.setItem("user", JSON.stringify(usr));
          setForm({
            email: "",
            password: "",
          });
          setFormType();
          setLoading(false);
          setUser(usr);
        } else {
          setVError(response.msgError);
        }
      });
  };
  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${u.user_id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
        }
      });
  };
  return (

    <div>
      <Header />
    <div className="login-page">


      
      <Modal
        onCancel={() => setFormType()}
        className="no-buttons-modal black-forget-modal "
        open={formType === "forget"}
      >
        <ForgetPassword />
      </Modal>
      <div className="container">
        <div className="row no-gutter">
          <div className="col-md-6 ">
            <div className="d-none d-md-flex bg-image login-box-bg">
              <div className="video-container radius-video">
                <ReactPlayer loop url={video} playing />
              </div>
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="login d-flex login-box-bg align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 col-xl-7 mx-auto">
                    <h3 style={{ fontSize: "40px"  , marginBottom:"30px" }} className="display-4">
                      Login
                    </h3>
                    {/* <p className="mb-4">Create a login split page using B.</p> */}
                    <form>
                      <div className="form-group mb-3">
                        <input
                          id="inputEmail"
                          type="email"
                          placeholder="Email address"
                          value={form.email}
                          required=""
                          autofocus=""
                          className="form-control rounded-pill border-0 shadow-sm px-4"
                          onChange={(e) =>
                            changeField(
                              "email",
                              e.target.value.toLowerCase(),
                              form
                            )
                          }
                        />
                      </div>
                      <div className="form-group mb-3">
                        <input
                          id="inputPassword"
                          type="password"
                          placeholder="Password"
                          onChange={(e) =>
                            changeField("password", e.target.value, form)
                          }
                          value={form.password}
                          required=""
                          className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                        />
                      </div>
                      {/* <GoogleAuth /> */}
                      <div className="custom-control custom-checkbox mb-3">
                        {/* <div className="login-forget-btn">
                          {" "}
                          <span
                            onClick={() => setFormType("forget")}
                            className=" signup-txt-login"
                          >
                            Forgot Password
                          </span>{" "}
                          <Link className=" signup-txt-login" to="/signup">
                            <span>Sign up</span>
                          </Link>
                        </div> */}
                      </div>
                      <div className="login-butotn-d">
                        <button
                          onClick={handleSubmit}
                          class="custom-button"
                          type="submit"
                        >
                          <strong>Login</strong>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={400}
        className="no-h-bo-b-modal no-close-model"
        open={verifyD}
      >
        <div className="verifybtn-c">
          <h5> Enter Verify Code</h5>
          <p>
            Please check your email <b>{form.email}</b> for verification code
          </p>
          <input
            onChange={(e) => setVerifyCode(e.target.value)}
            className={`form-control ${vError ? " error-input" : ""} `}
            type="number"
            value={verifyCode}
            placeholder="Verify code"
          />
          {vError ? <i>{vError}</i> : null}
          <div className="code-veri-lg">
            {res ? (
              <div>
                <GreenTick width="30px" height="30px" />
              </div>
            ) : (
              <Button onClick={resentCode} type="link">
                Resend Code
              </Button>
            )}
            <Button onClick={verifyEmail}>Verify</Button>
          </div>
        </div>
      </Modal>
    </div>
    </div>
  );
}
