import { Alert, Card, Form, Input, message, Spin, Switch, Tooltip } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaLaptop, FaMinus } from "react-icons/fa";
import Output from "./Output";
import { BiCheckDouble } from "react-icons/bi";
import Avatar from "react-avatar";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { RiSendPlaneFill } from "react-icons/ri";
import ReactPlayer from "react-player";
import { MdOutlineTravelExplore } from "react-icons/md";
import SearchContext from "../../../Context/SearchContext";
import UpgradeModal from "./UpgradeModal";
import { BsFillDatabaseFill } from "react-icons/bs";
import Profiles from "./Profiles";

export default function Chat() {
  const {
    assist,
    setassist,
    chatId,
    user,
    load,
    setLoad,
    callApi,
    setCallApi,
    setType,
    setFormType,
    incident,
    setincident,
    right,
    setRight,
    pActive,
  } = useContext(SearchContext);
  const [display, setDisplay] = useState();

  const [open, setOpen] = useState(false);
  const [d, setD] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [callS, setCallScrool] = useState(1);

  useEffect(() => {
    if (assist.pageNo === 1 && assist.data) {
      setLoading2(false);
    } else {
      getVideoHigh();
    }
  }, [callApi, chatId]);

  useEffect(() => {
    let v = [];
    if (incident === undefined) {
      for (let i = 0; i < 1000; i++) {
        v.push(`${i} incident`);
      }
      setincident(v);
    }
  }, []);

  useEffect(() => {
    if (open || load) {
      scrolBottom();
    }
  }, [open, load]);

  useEffect(() => {
    if (assist.data) {
      scrolBottom();
    }
  }, [callS]);

  const [comentNew, setComentNew] = useState("");

  const handleChange = (e) => {
    setComentNew(e.target.value);
  };
  const getVideoHigh = (h) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/chat_log?page=${
        assist.pageNo
      }&limit=15&user_id=${
        user ? user.user_id : "map"
      }&sort_by=${-1}&assistant_id=${
        pActive ? pActive._id : "645f00b8744d5ff6bc8571e8"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setLoading2(false);
        if (data && data.isSuccess) {
          setassist({
            ...assist,
            data: data.chatlogList
              .reverse()
              .concat(assist.data ? assist.data : []),
            totalPages: data.totalPages,
          });
          if (callApi === 1) {
            setTimeout(() => scrolBottom(), 1000);
          }
        }
      });
  };
  const findTimeStamp = (q) => {
    let a = q;
    const i = a.indexOf(":");
    if (i !== -1 && a.slice(i + 3, i + 4) === ":") {
      return a.slice(i - 2, i + 6);
    }
  };

  const scrolBottom = () => {
    const element = document.getElementsByClassName("active-tab-com-dfc22");
    if (element && element.length) {
      setTimeout(() => {
        element[0].scroll({
          top: element[0].scrollHeight,
          behavior: "smooth",
        });
      }, 300);
    }
  };
  const loadMore = (v) => {
    // console.log(v.target.scrollHeight);
    if (
      v.target.scrollTop === 0 &&
      assist.totalPages > assist.pageNo &&
      !loading
    ) {
      setassist({
        ...assist,
        pageNo: assist.pageNo + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi(callApi + 1);
    }
  };

  const updateFiledChat = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/chat_log/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const all = assist.data.map((m) =>
            m._id === data.chatlogData._id ? data.chatlogData : m
          );
          setassist({
            ...assist,
            data: all,
          });
        } else {
          message.warning("Something Went Wrong");
        }
      });
  };

  const updateFiled = (d) => {
    const obj = {
      chat_id: d._id,
      approve: true,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/v3/chapter/approve_action`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          d.is_approved = true;
          const all = assist.data.map((m) => (m._id === d._id ? d : m));
          setassist({
            ...assist,
            data: all,
          });
        } else {
          message.warning("Something Went Wrong");
        }
      });
  };
  const handleSubmit = (p) => {
    // if (comentNew === "") {
    //   return;
    // }
    const c = p ? p : comentNew;

    const txt = c.trim().length ? c.trim() : assist.upImage ? "Image Sent" : "";
    setLoad(txt);
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const obj = {
      query: txt,
      user_id: user ? user.user_id : "map",
      // assistant_id: "645f00b8744d5ff6bc8571e8",
      assistant_id: pActive ? pActive._id : "645f00b8744d5ff6bc8571e8",

      video_id: assist.selAssetID2 ? assist.selAssetID2._id : undefined,
      startTime: assist.selAssetID2 ? findTimeStamp(txt) : undefined,
      image_id: assist.upImage
        ? assist.upImage[0].purpose === "misc"
          ? assist.upImage[0]._id
          : assist.upImage[0].headclip_id
        : assist.selAssetID
        ? assist.selAssetID.purpose === "misc"
          ? assist.selAssetID._id
          : assist.selAssetID.headclip_id
        : undefined,
      media: assist.selAssetID2
        ? true
        : assist.upImage
        ? true
        : assist.selAssetID
        ? true
        : assist.chatMedia
        ? true
        : right.activeRight === "Search"
        ? true
        : right.activeRight === "Analytics"
        ? true
        : undefined,
      insights: right.activeRight === "Analytics" ? true : undefined,
    };
    setComentNew("");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/v3/chapter/chat`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoad();
        if (data.message === "Compute is not available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data && data.isSuccess) {
          const all = assist.data ? assist.data : [];
          let d = data.data;
          d.response = data.response ? data.response : undefined;
          d.is_approval = data.is_approval ? data.is_approval : undefined;
          d.isProduct = data.isProduct ? data.isProduct : false;
          d._id = data.chat_id ? data.chat_id : undefined;

          all.push(d);
          setassist({
            ...assist,
            idClip: chatId ? data.data : undefined,
            data: all,
            searchImages:
              d.clip_data && d.clip_data.length
                ? d.clip_data
                : assist.searchImages,
          });
          if (d.clip_data && d.clip_data.length) {
            
            setType("search");
          }

          setCallScrool(callS + 1);
        } else {
          if (
            data.message ===
            "Chat limit has been reached, please create an account"
          ) {
            // setFormType("signup");
          } else if (
            data.message === "Please upgrade account. Query limit reached"
          ) {
            if (user && user.name === undefined) {
              setFormType("signup");
            }
            setassist({
              ...assist,
              isUpgrade: true,
            });
          } else {
            message.error("Something weng wrong");
          }
        }
      });
  };
  return (
    <div className={` chat-bx-mn right-side-popup gradient-bg0c`}>
      <UpgradeModal />
      <div className="cht-bx-tops">
        <b onClick={() => console.log(assist)}>
          {pActive ? pActive.name : "Default Agent"}
        </b>{" "}
        <BsFillDatabaseFill
          onClick={() => setassist({ ...assist, isMedia: true })}
          className="brand-icon2 hide-desktop"
        />
        {/* <FaMinus
          onClick={() => {
            setRight({ ...right, activeAI: undefined });
          }}
        /> */}
      </div>
      {assist.selAssetID2Load ||
      assist.selAssetID2 ||
      assist.upImage ||
      assist.selAssetID ? (
        <div className="img-active-popcs">
          <div className="img-active-popcs2">
            {assist.selAssetID2Load ? (
              <div className="sel-img-id-ass">
                <Card className="card-bx-fl">
                  <div className="vd-nm-pr">
                    <ReactPlayer
                      url={assist.selAssetID2Load.file_url}
                      controls={false}
                    />
                  </div>
                  <div className="play-btn-vdc ">
                    <Spin size="large" className="brand-icon " />
                  </div>
                </Card>
              </div>
            ) : null}
            {assist.selAssetID2 ? (
              <div className="sel-img-id-ass">
                <div className="vd-nm-pr">
                  <ReactPlayer
                    url={assist.selAssetID2.file_url}
                    controls={false}
                  />
                </div>
                <GrFormClose
                  onClick={() =>
                    setassist({
                      ...assist,

                      selAssetID2: undefined,
                    })
                  }
                />
              </div>
            ) : null}
            {assist.upImage ? (
              <div className="sel-img-id-ass">
                <img className="" src={assist.upImage[0].file_url} alt="" />
                <GrFormClose
                  style={{ color: "white" }}
                  onClick={() =>
                    setassist({
                      ...assist,

                      upImage: undefined,
                    })
                  }
                />
              </div>
            ) : null}
            {assist.selAssetID ? (
              <div className="sel-img-id-ass">
                <img className="" src={assist.selAssetID.file_url} alt="" />
                <AiOutlineClose
                  style={{ color: "white" }}
                  onClick={() =>
                    setassist({
                      ...assist,

                      selAssetID: undefined,
                    })
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      <div
        onScroll={(v) => {
          loadMore(v);
        }}
        className="active-tab-com-dfc22"
      >
        {loading2 || assist.data === undefined ? (
          <div className="load-chat-ccs">
            <Spin />
          </div>
        ) : null}
        {assist.data && assist.data.length === 0 && load === undefined ? (
          <div className="no-heigh-div-c full-h-bx-a">
            <Alert
              message="Your New Agent"
              description={
                <div>
                  <div>
                    Your new agent can help you perform a range of visual tasks and chat about anything. 
                    Attach an image or video or just say hello
                  </div>
                </div>
              }
              type="info"
              showIcon
              className="brand-alert bg-txt-a"
            />
          </div>
        ) : null}
        {assist.data &&
          assist.data.map((cm, cmi) => {
            return (
              <div
                style={{
                  transition: "0.4s",
                }}
                className="assit-dat-cs"
                key={cmi}
              >
                <div className="chat-out-p1">
                  <div className="chat-qry-p">
                    <span>
                      <b>
                        <Avatar
                          textSizeRatio={1.75}
                          size={20}
                          round="20px"
                          name={user ? user.name : "O"}
                          style={{ marginRight: "10px", marginTop: "-7px" }}
                        />
                      </b>
                      {cm.query}
                    </span>
                    {cm.image_url && cm.image_url.length ? (
                      <div className="img-up-qur">
                        {cm.image_url.map((im, imi) => {
                          return <img src={im.file_url} alt="" />;
                        })}
                      </div>
                    ) : null}
                    <div className="dbl-tci-dvc">
                      <BiCheckDouble className="double-tick-brand" />
                    </div>
                  </div>
                </div>
                <div className="chat-out-p12">
                  <div
                    className={`chat-out-p ${
                      assist.activeClips ? " clips-m-size" : ""
                    }`}
                    style={{
                      marginBottom:
                        cmi + 1 === assist.data.length ? "0px" : "10px",
                    }}
                    onClick={() => setD(cm._id)}
                  >
                    <div className="flex-bxos">
                      <b>
                        <MdOutlineTravelExplore />

                        {/* <img
                          className="logo-icn-cht"
                          src={Icons.avIcon}
                          alt=""
                        /> */}
                      </b>
                      <div className="ai-chat-txt-c">
                        <span>
                          <Output
                            incident={incident}
                            data={cm.output}
                            row={cm}
                          />
                        </span>{" "}
                        {/* {!cm.isProduct &&
                  cm.clip_data &&
                  cm.clip_data.length ? (
                    <div className="video-icn-chrt-">
                      <FaVideo
                        onClick={() => chnageDisplay(cm, true)}
                      />
                    </div>
                  ) : (
                    <span />
                  )} */}
                      </div>
                    </div>
                    {cm.response || cm.is_approval || cm.is_approved ? (
                      <div className="chat-resp-c1">
                        <div className="chat-resp-c">
                          <p>{cm.response}</p>
                        </div>

                        <span
                          onClick={() => {
                            if (cm.is_approved) {
                            } else {
                              updateFiled(cm);
                            }
                          }}
                          className={`btn-appr ${
                            cm.is_approved ? " btn-apr-s" : ""
                          }`}
                        >
                          {cm.is_approved ? "Action Taken" : "Approve"}
                        </span>
                      </div>
                    ) : null}
                    <div className="date-tm-chat flex-bxos">
                      <div></div>
                      <span>
                        {moment(cm.createdAt).format("lll")}
                        {/* {cm.isEdit ? <Create row={cm} /> : <span />} */}
                      </span>
                    </div>
                  </div>
                </div>
                {/* {isSources ? (
                  <>
                    {(cm.analytics && cm.analytics.length) ||
                    (cm.trend_data && cm.trend_data.length) ? (
                      <div className="chat-main-top-ch">
                        <ChartsArea data={cm} />
                      </div>
                    ) : cm.clip_data && cm.clip_data.length ? (
                      <div className="chat-main-top-ch">
                        <MomentsChat title={"Moments"} data={cm} />
                      </div>
                    ) : null}
                    {cm.image_analytics && cm.image_analytics.length ? (
                      <ImageAnalyticsChart data={cm} />
                    ) : null}

                    {cm.isProduct ? <Products data={cm} /> : null}

                    {cm.map_load && cm.map_load.length ? (
                      <MapChat data={cm} />
                    ) : null}
                  </>
                ) : null} */}

                {cm.isCreated ? (
                  <p style={{ flexDirection: "row" }} className="chat-out-p">
                    <b>
                      <FaLaptop className="o-icn-dcp" />
                    </b>
                    <span>
                      <div className="typing-loader"></div>
                    </span>
                  </p>
                ) : null}
              </div>
            );
          })}
        {load ? (
          <div style={{ marginTop: "10px" }}>
            <div className="chat-out-p1">
              <p
                style={{ display: "flex", flexDirection: "row" }}
                className="chat-qry-p"
              >
                <b style={{ marginRight: "10px" }}>
                  <Avatar
                    textSizeRatio={1.45}
                    size={20}
                    round="20px"
                    name={user ? user.name : "O"}
                  />
                </b>
                <span>
                  {load} <BiCheckDouble className="double-tick-b" />
                </span>
              </p>
            </div>
            <div className="chat-out-p12">
              <p
                style={{ flexDirection: "row", width: "70px" }}
                className="chat-out-p"
              >
                <span>
                  <div className="typing-loader"></div>
                </span>
              </p>
            </div>
          </div>
        ) : null}
        {/* </>
  )} */}
      </div>
      {assist.data ? (
        <div className="bottom-chat-c">
          <div className="main-com-bx-1">
            <Form
              onFinish={() => {
                handleSubmit();
              }}
              style={{ marginBottom: "0px", width: "100%" }}
            >
              <Form.Item
                style={{ marginBottom: "0px" }}
                className="create-cmt-vdc"
              >
                <Input
                  style={{ marginBottom: "0px" }}
                  placeholder="Write a message"
                  onChange={handleChange}
                  value={comentNew}
                  size="large"
                />
                <div className="icns-icns-st-rt">
                  {load ? (
                    <Spin />
                  ) : (
                    <RiSendPlaneFill
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  )}
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : null}
    </div>
  );
}
