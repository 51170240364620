import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import {
  Alert,
  Button,
  Modal,
  Space,
  Spin,
  Table,
  Tooltip,
  message,
  Row,
  Col
} from "antd";
import moment from "moment";
import { FaPlay } from "react-icons/fa";
import ReactPlayer from "react-player";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import { TiTickOutline } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
export default function GeneratedVideos() {
  const playerRef = React.useRef();
  const { user, theme, setType, setGenerate } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(1);
  const [loading2, setLoading2] = useState(true);
  const [display, setDisplay] = useState(false);
  const [generated, setGenerated] = useState({ pageNo: 1 });
  const [video, setVideo] = useState();
  const [selectedVideoId, setSelectedVideoId] = useState(); 

  useEffect(() => {
    if (callApi > 1) {
      getVideos();
    }
  }, [callApi]);

  const handleUpscaleClick = () => {
    
    const requestBody = {
      user_id: user.user_id,
      video_id: selectedVideoId
    };

    message.info({
      content: (
        <div>
          Your video is being upscaled. Navigate back to the queue to monitor the generation.
        </div>
      ),
      duration: 4, // Message won't auto-close
      key: 'upscalingMessage' // Use a unique key to prevent duplicates
    });

    fetch('https://embed.axv.ai/v1/upscale', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response data here
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  

  const getVideos = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${
        generated.pageNo
      }&limit=6&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=original&status=generated`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          setGenerated({
            ...generated,
            videos: data.imageList,
            totalPages: data.totalPages,
          });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "Query",
      key: "query",
      render: (_, record) => (
        <Space size="middle">
          <span>{record.query}</span>
        </Space>
      ),
    },

    {
      title: "Created At",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <span>{moment(record.createdAt).fromNow()}</span>
        </Space>
      ),
    },
    {
      title: "",
      key: "v",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => {
            setVideo(record.file_url); // Set the video URL
            setSelectedVideoId(record._id); // Set the selected video's _id
          }} type="primary">
            <FaPlay />
          </Button>
        </Space>
      ),
    },

    {
      title: "Queue",
      key: "quene",
      render: (_, record) => (
        <Space size="middle">
          <span className="icons-bx-sd">
            {record.quene === "complete" ? (
              <TiTickOutline className="gren-tick-t" />
            ) : record.quene === "progress" ? (
              <Spin indicator={antIcon} />
            ) : (
              <AiOutlineCloseCircle className="red-tick-t" />
            )}
          </span>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Tooltip color="white" title="Get Generated Videos">
        <b
          style={{
            display: "flex",
            alignItems: "center", // Align items vertically in flex container
            gap: "8px", // Add spacing between icon and text
          }}
          onClick={() => {
            setDisplay(true);
            setCallApi(callApi + 1);
          }}
        >
          Queue
        </b>
      </Tooltip>
      <Modal
        width={900}
        className={`no-h-bo-b-modal brand-modal2 brand-modal chat-mmmd ${theme}-theme`}
        open={display}
        onCancel={() => {
          setDisplay(false);
          setGenerated({ pageNo: 1 });
          setVideo();
        }}
      >
        <h3>
          Generated Videos{" "}
          {loading2 ? (
            <Spin />
          ) : (
            <Button
              onClick={() => {
                setLoading2(true);
                setCallApi(callApi + 1);
              }}
              type="primary"
            >
              Refresh
            </Button>
          )}
        </h3>{" "}
        {video ? (
     <div className="custom-video-container">
     <div className="back-icon-box" style={{ position: 'absolute', top: '2px', left: '0' }}>
       <BsFillArrowLeftSquareFill
         onClick={() => {
           setVideo();
         }}
       />
     </div>
     <div className="video-top-right video-proper">
       <ReactPlayer url={video} controls ref={playerRef} playing />
       
       {/* Upscale Button */}
       <Row gutter={16} align="middle">
            <Col span={8}>
              <Alert
                
                description="The video rendered is a preview of your video. You can upscale it to higher quality"
                type="info"
              
              />
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Button className="glow-on-hover" type="primary" onClick={handleUpscaleClick}>
                Upscale To Higher Quality
              </Button>
            </Col>
          </Row>
     </div>
   </div>
   
   
        ) : generated.videos ? (
          generated.videos.length ? (
            <div>
              <Table
                pagination={{
                  pageSize: 8,
                  current: generated.pageNo,
                  total: generated.totalPages * 6,
                  onChange: (v) => {
                    setGenerated({ ...generated, pageNo: v });
                    setCallApi(callApi + 1);
                  },
                  size: "small",
                  showSizeChanger: false,
                }}
                dataSource={generated.videos}
                columns={columns}
              />
              <a
                href="https://docs.olm.ai/product/create/create-new-videos"
                target="_blank"
                className="card-buttonoi"
              >
                How Generations Work
              </a>
            </div>
          ) : (
            <div
              // onScroll={(v) => {
              //   loadMore(v);
              // }}
              className="act-logs-fcv"
            >
              <div style={{ marginTop: "10px" }} className="no-heigh-div-c">
                <Alert
                  title="ss"
                  description="AI generated video jobs will appear here"
                  className="brand-alert bg-txt-a"
                />
                <br />
                <br />
              </div>
            </div>
          )
        ) : null}
      </Modal>
    </>
  );
}
