import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { FaUser, FaLock } from "react-icons/fa";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  message,
  notification,
  Modal,
  Spin,
} from "antd";
import { AiFillMail } from "react-icons/ai";
import bcrypt from "bcryptjs";
import { Link } from "react-router-dom";
import SearchContext from "../../../Context/SearchContext";

const ForgetPassword = () => {
  const { formType, setFormType, setAfterSU, user } = useContext(SearchContext);

  const [form, setForm] = useState({
    email: "",
    password: "",
    code: "",
  });
  const [loadingS, setloadingS] = useState();
  const [step, setStep] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 1) {
      setloadingS(true);
      setCodeToEmail(form);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setloadingS(true);

      resetPassword(form);
    }
  };

  const setCodeToEmail = (user) => {
    const obj = {
      email: user.email,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/consumer/forgot_pass`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloadingS(false);
        if (response && response.isSuccess) {
          setStep(2);
          notification.success({
            message: response.message,
          });
        } else {
          notification.error({
            message: response.message,
          });
        }
      });
  };

  const resetPassword = (user) => {
    const pass = bcrypt.hashSync(
      user.password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    const obj = {
      email: user.email,
      code: user.code,
      password: pass,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/consumer/reset_pass`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloadingS(false);
        if (response && response.isSuccess) {
          notification.success({
            message: response.message,
          });
          setStep(1);
          setForm({
            email: "",
            password: "",
            code: "",
          });
          setFormType("login");
        } else {
          notification.error({
            message: response.message,
          });
        }
      });
  };

  const changeField = (key, val, row) => {
    // console.log(key, val, row);
    row[key] = val;
    setForm({ ...form, [key]: val });
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="login-form">
        {step === 1 ? (
          <>
            <h4>Verify Email</h4>
            <p>We will send a code to your email to verify.</p>
            <Form.Item>
              <Input
                onChange={(e) =>
                  changeField("email", e.target.value.toLowerCase(), form)
                }
                size="large"
                prefix={<AiFillMail className="input-icon-l" />}
                placeholder="Email"
                value={form.email}
                defaultValue=""
              />
            </Form.Item>
          </>
        ) : null}
        {step === 2 ? (
          <>
            <p>Check Your Email ({form.email}) For Cerification Code</p>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Input
                onChange={(e) => changeField("code", e.target.value, form)}
                size="large"
                prefix={<FaLock className="input-icon-l" />}
                type="text"
                placeholder="Code"
                value={form.code}
                defaultValue=""
              />
            </Form.Item>
          </>
        ) : null}
        {step === 3 ? (
          <>
            <p>Add New Password</p>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Input.Password
                onChange={(e) => changeField("password", e.target.value, form)}
                size="large"
                prefix={<FaLock className="input-icon-l" />}
                type="password"
                placeholder="Password"
                value={form.password}
                defaultValue=""
              />
            </Form.Item>
          </>
        ) : null}
        <Form.Item style={{ marginBottom: "0px" }}>
          <div className="for-reg-c">
            <Link to="#" onClick={() => setFormType("login")}>
              Login
            </Link>
          </div>
          <div className="login-btn-c21">
            {step === 2 ? (
              <Button onClick={setCodeToEmail} type="link">
                Resend Code
              </Button>
            ) : (
              <span />
            )}
            {loadingS ? (
              <Spin />
            ) : (
              <Button
                disabled={step === 2 && form.code.length === 0}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {step === 1 ? "Send Code" : step === 2 ? "Next" : "Reset"}
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgetPassword;
