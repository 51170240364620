import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import { Alert, Button, Col, Input, Row, Spin } from "antd";
import ReactPlayer from "react-player";
import moment from "moment";
import GreenTick from "../../../components/GreenTick";
import { LoadingOutlined } from "@ant-design/icons";

export default function AsyncTab() {
  const { generate, user, setGenerate } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStore();
  }, [callApi]);

  const getStore = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/store?page=${
        generate.pageNo3
      }&limit=50&sort_by=-1&user_id=${user && user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        if (response && response.isSuccess) {
          setGenerate({
            ...generate,
            store: response.storeList,
            totalPages3: response.totalPages,
          });
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const loadMore3 = (v, key) => {
    if (
      v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
      generate.totalPages3 > generate.pageNo3 &&
      !loading
    ) {
      setGenerate({
        ...generate,
        pageNo3: generate.pageNo3 + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading(true);
      setCallApi(callApi + 1);
    }
  };
  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setLoading(true);
            setCallApi(callApi + 1);
          }}
        >
          Refresh
        </Button>
      )}
      {generate.store && generate.store.length === 0 ? (
        <div style={{ marginTop: "10px" }} className="no-heigh-div-c">
          <Alert
            message="Async allows you to run generation jobs in the background while closing the browser. Those new generations will appear here"
            className="brand-alert bg-txt-a"
          />
        </div>
      ) : null}

      <div
        onScroll={(v) => {
          loadMore3(v, "pageNo3");
        }}
        className="act-logs-fcv"
      >
        {generate.store &&
          generate.store.map((im, imi) => {
            return (
              <div style={{ width: "100%" }} key={imi}>
                <div onClick={() => console.log(im)} className={`img-bx-imgsc`}>
                  {im.file_url.indexOf(".mp4") !== -1 ||
                  im.file_url.indexOf(".avi") !== -1 ||
                  im.file_url.indexOf(".mkv") !== -1 ||
                  im.file_url.indexOf(".mov") !== -1 ||
                  im.file_url.indexOf(".wmv") !== -1 ||
                  im.file_url.indexOf(".webm") !== -1 ||
                  im.file_url.indexOf(".mpeg") !== -1 ||
                  im.file_url.indexOf(".3gp") !== -1 ||
                  im.file_url.indexOf(".flv") !== -1 ? (
                    <div className="vd-nm-pr">
                      <ReactPlayer url={im.file_url} controls={false} />
                    </div>
                  ) : (
                    <div style={{ height: "60px" }}>
                      <img src={im.file_url} alt="" />
                    </div>
                  )}
                  <div className="img-bx-imgdt">
                    <div className="img-r-ast-b2">
                      <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        // onChange={(e) => {
                        //   changeField(im, "meta_data", e.target.value);
                        // }}
                        value={im.generations ? im.generations : ""}
                        // onBlur={() =>
                        //   updateTask({ meta_data: im.meta_data }, im._id)
                        // }
                        placeholder="Pending"
                      />
                    </div>
                  <br/>
                  <br/>
                  </div>
                  {im.status === "complete" ? (
                    <GreenTick width="20px" height="20px" />
                  ) : (
                    <Spin indicator={antIcon} />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
