import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Input,
  Progress,
  Row,
  Spin,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { TiAttachmentOutline, TiTick } from "react-icons/ti";
import { BsChatQuoteFill, BsInfoCircle } from "react-icons/bs";
import { GrAttachment } from "react-icons/gr";
import ReactPlayer from "react-player";
import { FaImages, FaMinus, FaPlay } from "react-icons/fa";
import { BiUpload } from "react-icons/bi";
import { MdClose, MdKeyboardBackspace } from "react-icons/md";
import Paragraph from "antd/lib/typography/Paragraph";
import UploadAssets from "./UploadAssets";
import SearchContext from "../../../Context/SearchContext";
import Profiles from "./Profiles";
import GenerateTab from "./GenerateTab";
import AsyncTab from "./AsyncTab";
import FavoritesTab from "./FavoritesTab";
export default function Assests({ active, setActive }) {
  const {
    images,
    setImages,
    pActive,
    setassist,
    assist,
    right,
    setRight,
    generate,
    user,
    type,
    setType,
    setGenerate,
  } = useContext(SearchContext);
  const playerRef = React.useRef();
  const [open, setOpen] = useState(false);

  const [loading2, setLoading2] = useState(true);
  const [callApi, setCallApi] = useState(1);
  const [callApi2, setCallApi2] = useState(1);
  const [loading, setLoading] = useState(false);
  const [play, setPlay] = useState();
  const [act, setAct] = useState("");

  useEffect(() => {
    if (images.pageNo !== 1) {
      getImages();
    }
    if (images.data && images.pageNo === 1) {
      setLoading2(false);
    }
  }, [callApi]);

  useEffect(() => {
    if (images.pageNoV !== 1) {
      getVideos();
    }
  }, [callApi2]);

  useEffect(() => {
    if (images.data === undefined) {
      getImages();
    }
    if (type === "video" && images.videos === undefined) {
      getVideos();
    }
    if (type === "fine_tune" && images.tune === undefined) {
      getTune();
    }
  }, [type]);

  const getVideos = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/image?page=${
        images.pageNoV
      }&limit=15&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=original`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          setImages({
            ...images,
            videos: images.videos
              ? images.videos.concat(data.imageList)
              : [].concat(data.imageList),
            totalPages2: data.totalPages,
          });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const getImages = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/image?page=${
        images.pageNo
      }&limit=15&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=visual`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          setImages({
            ...images,
            data: images.data
              ? images.data.concat(data.imageList)
              : [].concat(data.imageList),
            totalPages: data.totalPages,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };

  const getTune = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/image?page=${
        images.pageNo
      }&limit=15&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=fine_tune`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          setImages({
            ...images,
            tune: images.tune
              ? images.tune.concat(data.imageList)
              : [].concat(data.imageList),
            totalPagesT: data.totalPages,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };

  const changeField = (row, key, val) => {
    row[key] = val;
    const all = images.tune.map((m) => (m._id === row._id ? row : m));
    setImages({
      ...images,
      tune: all,
    });
  };

  const getClips = (d) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/image?page=${
        images.pageNo
      }&limit=15&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&headclip_id=${d._id}&sort=startTime&summary=true`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          setImages({
            ...images,
            videoImages: data.imageList,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };

  const loadMore = (v, key) => {
    if (
      v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
      images.totalPages > images.pageNo &&
      !loading2
    ) {
      setImages({
        ...images,
        pageNo: images.pageNo + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi(callApi + 1);
    }
  };

  const loadMore2 = (v, key) => {
    if (
      v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
      images.totalPages2 > images.pageNoV &&
      !loading2
    ) {
      setImages({
        ...images,
        pageNoV: images.pageNoV + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi2(callApi2 + 1);
    }
  };
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://int.display.video/api/image/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = images[key].filter((p) => p._id !== row._id);
          setImages({ ...images, [key]: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const updateTask = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          // message.success("Deleted");
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const adjustClick = (im, key1, key2) => {
    if (
      right.activeRight !== "Generate" ||
      generate.activeType === "custom_image"
    ) {
      setassist({
        ...assist,
        selAssetID: im,
        upImage: undefined,
        selAssetID2: undefined,
      });

      setRight({
        ...right,
        activeAI: "message",
        activeIcon: undefined,
      });
    } else {
      const all = generate.selectedIMG ? [...generate.selectedIMG] : [];
      const find = all.filter((f) => f._id === im._id);

      if (find.length) {
        setGenerate({
          ...generate,
          selectedIMG: all.filter((f) => f._id !== im._id),
          selectedVID: undefined,
        });
      } else {
        setGenerate({
          ...generate,
          selectedIMG: [...all, im],
          selectedVID: undefined,
        });
      }
    }
  };

  const adjustClickV = (im, key1) => {
    if (
      right.activeRight !== "Generate" ||
      generate.activeType === "clips" ||
      generate.activeType === "custom_image"
    ) {
      setassist({
        ...assist,
        selAssetID2: im,
        selAssetID: undefined,
        upImage: undefined,
      });
    } else {
      const all = generate.selectedVID ? [...generate.selectedVID] : [];
      const find = all.filter((f) => f._id === im._id);

      if (find.length) {
        setGenerate({
          ...generate,
          selectedVID: all.filter((f) => f._id !== im._id),
          selectedIMG: undefined,
        });
      } else {
        setGenerate({
          ...generate,
          selectedVID: [...all, im],
          selectedIMG: undefined,
        });
      }
    }
  };

  return (
    <div
      className={` chat-bx-mn right-side-popup gradient-bg0c right-assist-c`}
      // onClick={() => console.log(generate)}
    >
      <div className="cht-bx-tops">
        <b>Media</b>

        <FaMinus
          className="brand-icon2 hide-desktop"
          onClick={() => {
            setassist({ ...assist, isMedia: undefined });
          }}
        />
      </div>

      <div
        style={{ paddingTop: "4px" }}
        className="activity-log-main-c guide-main-chat-r"
      >
        <div className="act-logs-fcvhjg">
          <div className="bx-main-sel-prf">
           {/* {generate.styles ? (
              <span
                onClick={() => setType("Styles")}
                className={`btn-appr select-tcbn-a ${
                  type === "Styles" ? " btn-apr-s" : ""
                }`}
              >
                <span>Styles</span>
              </span>
              ) : null}*/}
           {/* {generate.favorites ? (
              <span
                onClick={() => setType("Favorites")}
                className={`btn-appr select-tcbn-a ${
                  type === "Favorites" ? " btn-apr-s" : ""
                }`}
              >
                <span>Favorites</span>
              </span>
            ) : null}*/}
            {generate.store ? (
              <span
                onClick={() => setType("Async")}
                className={`btn-appr select-tcbn-a ${
                  type === "Async" ? " btn-apr-s" : ""
                }`}
              >
                <span>Async</span>
              </span>
            ) : null}
            {generate.data || generate.clips || generate.imagesGens ? (
              <span
                onClick={() => setType("Generate")}
                className={`btn-appr select-tcbn-a ${
                  type === "Generate" ? " btn-apr-s" : ""
                }`}
              >
                <span>Generate</span>
              </span>
            ) : null}
            {assist.searchImages ? (
              <span
                onClick={() => setType("search")}
                className={`btn-appr select-tcbn-a ${
                  type === "search" ? " btn-apr-s" : ""
                }`}
              >
                <span>Search</span>
              </span>
            ) : null}
            <span
              onClick={() => setType("image")}
              className={`btn-appr select-tcbn-a ${
                type === "image" ? " btn-apr-s" : ""
              }`}
            >
              <span>Images</span>
            </span>
            <span
              onClick={() => setType("video")}
              className={`btn-appr select-tcbn-a ${
                type === "video" ? " btn-apr-s" : ""
              }`}
            >
              <span>Videos</span>
            </span>
            <span
              onClick={() => setType("fine_tune")}
              className={`btn-appr select-tcbn-a ${
                type === "fine_tune" ? " btn-apr-s" : ""
              }`}
            >
              <span>Fine Tune</span>
            </span>
            <span
              onClick={() => setType("profile")}
              className={`btn-appr select-tcbn-a ${
                type === "profile" ? " btn-apr-s" : ""
              }`}
            >
              <span>Agents</span>
            </span>
          </div>
        </div>

        {(right.activeRight === "Async" || generate.store) &&
        type === "Async" ? (
          <AsyncTab />
        ) : null}
        {type === "profile" ? <Profiles /> : null}
        {type === "Favorites" ? <FavoritesTab /> : null}
        {type === "Generate" ? <GenerateTab /> : null}
        {type === "fine_tune" ? (
          <div
            // onScroll={(v) => {
            //   loadMore(v);
            // }}
            className="act-logs-fcv"
          >
            {(images.tune && images.tune.length === 0) ||
            pActive === undefined ? (
              <div style={{ marginTop: "10px" }} className="no-heigh-div-c">
  <Alert
    message="Fine Tuning allows you to fine tune our models to understand your representation of the visual world"
    className="brand-alert bg-txt-a"
  />
  <br />
  <br />
</div>
            ) : null}

            <Row>
              {pActive &&
                images.tune &&
                images.tune.map((im, imi) => {
                  return (
                    <Col key={imi} lg={{ span: 24 }}>
                      <div
                        className={`img-bx-imgsc ${
                          assist.selAssetID && im._id === assist.selAssetID._id
                            ? " sel-asset-c"
                            : ""
                        }`}
                      >
                        <div style={{ height: "60px" }}>
                          <img src={im.file_url} alt="" />
                        </div>
                        <div className="img-bx-imgdt">
                          <div className="img-r-ast-b2">
                            <Input.TextArea
                              autoSize={{ minRows: 2, maxRows: 3 }}
                              onChange={(e) => {
                                changeField(im, "meta_data", e.target.value);
                              }}
                              value={im.meta_data}
                              onBlur={() =>
                                updateTask({ meta_data: im.meta_data }, im._id)
                              }
                              placeholder="Embedding"
                            />
                          </div>

                          <i>{moment(im.createdAt).fromNow()}</i>
                        </div>
                        <div className="close-icn-asst-c">
                          <Popconfirm
                            title="Delete Image"
                            description="Are you sure you to delete this image?"
                            onConfirm={() => deleteTask(im, "data")}
                            okText="Yes"
                            cancelText="No"
                          >
                            <MdClose />
                          </Popconfirm>
                        </div>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {images.data && images.data.length === 0 ? (
              <div className="no-heigh-div-c ">
                <Alert
                  message="No Assets Exist"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-a"
                />
              </div>
            ) : null}
            {loading2 ? <Spin /> : null}
          </div>
        ) : null}
        {type === "image" ? (
          <div
            onScroll={(v) => {
              loadMore(v, "pageNo");
            }}
            className="act-logs-fcv"
          >
            <Row>
              {images.data &&
                images.data.map((im, imi) => {
                  return (
                    <Col
                      key={imi}
                      lg={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 16 }}
                    >
                      <div
                        className={`img-bx-imgsc ${
                          (assist.selAssetID &&
                            im._id === assist.selAssetID._id &&
                            (right.activeRight !== "Generate" ||
                            generate.activeType === "custom_image")) ||
                          (right.activeRight === "Generate" &&
                            generate.selectedIMG &&
                            generate.activeType !== "custom_image" &&
                            generate.selectedIMG.filter((f) => f._id === im._id)
                              .length)
                            ? " sel-asset-c"
                            : ""
                        }`}
                      >
                        <div className="img-bx-mnb">
                          <img
                            onClick={() => {
                              adjustClick(im, "selectedIMG", "selectedVID");
                            }}
                            src={im.file_url}
                            alt=""
                          />
                        </div>

                        <div className="close-icn-asst-c">
                          <Popconfirm
                            title="Delete Image"
                            description="Are you sure you to delete this image?"
                            onConfirm={() => deleteTask(im, "data")}
                            okText="Yes"
                            cancelText="No"
                          >
                            <MdClose />
                          </Popconfirm>
                        </div>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {images.data && images.data.length === 0 ? (
              <div className="no-heigh-div-c ">
                <Alert
                  message="No Assets Exist"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-a"
                />
              </div>
            ) : null}
            {loading2 ? <Spin /> : null}
          </div>
        ) : null}
        {type === "search" ? (
          <div className="act-logs-fcv">
            <Row>
              {assist.searchImages &&
                assist.searchImages.map((im, imi) => {
                  return (
                    <Col
                      key={imi}
                      lg={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 2 }}
                    >
                      <div
                        className={`img-bx-imgsc ${
                          assist.selAssetID && im._id === assist.selAssetID._id
                            ? " sel-asset-c"
                            : ""
                        }`}
                      >
                        <div className="img-bx-mnb">
                          <img
                            onClick={() => {
                              setassist({
                                ...assist,
                                selAssetID: im,
                                upImage: undefined,
                                selAssetID2: undefined,
                              });

                              setRight({
                                ...right,
                                activeAI: "message",
                                activeIcon: undefined,
                              });
                            }}
                            src={im.file_url}
                            alt=""
                          />
                        </div>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
        ) : null}
        {type === "video" ? (
          <div
            onScroll={(v) => {
              loadMore2(v, "pageNoV");
            }}
            className="act-logs-fcv"
          >
            {/* {act ? null : (
              <Button
                onClick={() => {
                  setLoading2(true);
                  setImages({
                    ...images,
                    videos: undefined,
                    totalPages2: undefined,
                  });
                  setCallApi(callApi + 1);
                }}
              >
                Refresh
              </Button>
            )} */}
            {images.videos && images.videos.length === 0 ? (
              <div className="no-heigh-div-c ">
                <Alert
                  message="No Assets Exist"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-a"
                />
              </div>
            ) : null}
            {images.videoImages ? (
              <div>
                <MdKeyboardBackspace
                  className="back-icon-csf"
                  onClick={() => {
                    setAct("");
                    setImages({
                      ...images,
                      videoImages: undefined,
                    });
                  }}
                />
              </div>
            ) : null}
            <Row>
              {images.videos &&
                images.videos
                  .filter((f) => f.file_url.includes(act))
                  .map((im, imi) => {
                    return (
                      <Col
                        key={imi}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 2 }}
                      >
                        <div
                          key={imi}
                          className={`img-bx-imgsc vd-bx-nn ${
                            (assist.selAssetID2 &&
                              im._id === assist.selAssetID2._id &&
                              (right.activeRight !== "Generate" ||
                                generate.activeType === "clips" ||
                                generate.activeType === "custom_image")) ||
                            (right.activeRight === "Generate" &&
                              generate.activeType !== "clips" &&
                              generate.activeType !== "custom_image" &&
                              generate.selectedVID &&
                              generate.selectedVID.filter(
                                (f) => f._id === im._id
                              ).length)
                              ? " sel-asset-c"
                              : ""
                          }`}
                        >
                          <div
                            onClick={() => {
                              adjustClickV(im, generate.selectedVID);
                            }}
                            className="vd-nm-pr"
                          >
                            <ReactPlayer url={im.file_url} controls={false} />
                          </div>
                          <div className="img-bx-imgdt">
                            <div className="img-r-ast-b2">
                              <b>
                                <Paragraph
                                  ellipsis={{
                                    rows: 2,
                                    // expandable: true,
                                    symbol: "see more",
                                  }}
                                  className="desc-p-crdjjkl"
                                >
                                  {im.meta_data}
                                </Paragraph>
                              </b>
                              <div className="icons-bx-ri-vd">
                                <FaPlay
                                  onClick={() => setPlay(im)}
                                  style={{ marginRight: "px" }}
                                  className="r-atc-incs"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="close-icn-asst-c">
                            <Popconfirm
                              title="Delete Video"
                              description="Are you sure to delete this video?"
                              onConfirm={() => deleteTask(im, "videos")}
                              okText="Yes"
                              cancelText="No"
                            >
                              <MdClose />
                            </Popconfirm>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
            </Row>

            {images.videoImages &&
              images.videoImages.map((im, imi) => {
                return (
                  <div key={imi} className={`img-bx-imgsc`}>
                    <div>
                      <img src={im.file_url} alt="" />
                    </div>
                    <div className="img-bx-imgdt">
                      <div className="img-r-ast-b2">
                        <FaPlay
                          onClick={() => setPlay(act)}
                          style={{ marginRight: "10px" }}
                          className="r-atc-incs"
                        />
                        {/* <p>{im.summary}</p> */}
                      </div>

                      <i>{im.startTime}</i>
                    </div>
                  </div>
                );
              })}

            {loading2 ? <Spin /> : null}
          </div>
        ) : null}
        {type === "profile" ||
        type === "Styles" ||
        type === "Favorites" ||
        type === "Generate" ||
        (type === "fine_tune" &&
          pActive === undefined) ? null : right.upOpen ? (
          <UploadAssets setOpen={setOpen} type2={type} />
        ) : (
          <div className="div-bc-uplc">
            <span
              onClick={() => setRight({ ...right, upOpen: true })}
              style={{
                width: "100px",
              }}
              className={`btn-appr select-tcbn-a `}
            >
              {/* <BiUpload size={24} /> */}
              <div>Upload</div>
            </span>
          </div>
        )}
        <Modal
          className="no-h-bo-b-modal top-20-x brand-modal"
          open={play !== undefined}
          onCancel={() => setPlay()}
          width={600}
        >
          {play ? (
            <div className="video-top-right video-proper-v">
              <ReactPlayer
                url={act ? act : play.file_url}
                controls
                ref={playerRef}
                playing={play !== undefined}
              />
            </div>
          ) : null}
        </Modal>
      </div>
    </div>
  );
}
