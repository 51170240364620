import React, { useContext, useEffect, useState } from "react";
import Home from "./Home";
import SearchContext from "../../Context/SearchContext";
import { v4 as uuidv4 } from "uuid";
import Header from "../../components/Header";
import { Button, Modal } from "antd";

export default function Index() {
  const {
    pActive,
    setUser,
    assist,
    setassist,
    incident,
    setincident,
    loginChange,
    setPActive,
    setProfile,
    profile,
  } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const [errorModal, setErrModal] = useState(false);
  const [check, setChek] = useState(1);
  useEffect(() => {
    let v = [];
    if (incident === undefined) {
      // for (let i = 0; i < 1000; i++) {
      //   v.push(`${i} incident`);
      // }
      setincident(v);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setChek(check + 1);
    }, 15000);
  }, []);

  useEffect(() => {
    if (loading === true && check !== 1) {
      setErrModal(true);
      setLoading(false);
    }
  }, [check]);
  useEffect(() => {
    setLoading(true);
    const u = JSON.parse(window.localStorage.getItem("user"));
    let obj;
    if (u) {
      obj = u;
      setUser(u);
    } else {
      let isID = JSON.parse(window.localStorage.getItem("isID"));
      let user_id;
      if (isID) {
        user_id = isID.user_id;
      } else {
        user_id = uuidv4();
        window.localStorage.setItem("isID", JSON.stringify({ user_id }));
      }
      obj = { user_id };
      setUser(obj);
    }

    if (pActive === undefined) {
      getPersons(obj);
    } else if (obj.id) {
      getChat(obj);
    } else {
      setLoading(false);
    }
  }, [loginChange]);
  const getChat = (u, p) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/chat_log?page=${
        assist.pageNo
      }&limit=15&user_id=${u.user_id}&sort_by=${-1}&assistant_id=${
        pActive ? pActive._id : p ? p._id : "645f00b8744d5ff6bc8571e8"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setassist({
            ...assist,
            data: data.chatlogList
              .reverse()
              .concat(assist.data ? assist.data : []),
            totalPages: data.totalPages,
          });
        }
        setLoading(false);
      });
  };
  const getPersons = (obj) => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/person/?page=1&limit=15&sort_by=${-1}&profile_id=${
        u ? u.profile_id : "onboard"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setProfile({
            ...profile,
            data: data.personList,
            totalPages: data.totalPages,
          });
          if (pActive === undefined) {
            setPActive(data.personList[0]);
          }
        }
        if (obj.id) {
          getChat(obj, data.personList ? data.personList[0] : undefined);
        } else {
          setLoading(false);
        }
      });
  };
  return (
    <div>
      <div className="app-main-bx">
        <Header />
        {!loading ? <Home /> : <div className="custom-loader"></div>}
      </div>
      <Modal
        width={440}
        className="no-h-bo-b-modal no-close-m brand-modal2 brand-modal chat-mmmd "
        open={errorModal}
        onCancel={() => setErrModal(false)}
      >
        <h1>Service at Capacity</h1>
        <p>
          Unfortunately we've hit some technical scaling errors and will be back
          shortly
        </p>
        <div className="btn-m-close-c2">
          <Button onClick={() => setErrModal(false)}>Close</Button>
        </div>
      </Modal>
    </div>
  );
}
