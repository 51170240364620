import React, { useContext } from "react";
import SearchContext from "../Context/SearchContext";
import { Button } from "antd";
import { MdOutlineCreate, MdOutlineImageSearch } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { BsMagic } from "react-icons/bs";

export default function Pricing() {
  const { organization, user, setOrganization } = useContext(SearchContext);

  return (
    <div>
      <div className="row bx-main-dgbcs">
        <h2>Pro</h2>

        <div className="icons-bx-main-ne mn-bvckks">
          <div>
            <MdOutlineImageSearch />
            <b>Search</b>
          </div>
          <div>
            <MdOutlineCreate />
            <b>Generate</b>
          </div>

          <div>
            <BsMagic />

            <b>Create</b>
          </div>
          <div>
            <FaUser />
            <b>Per User</b>
          </div>
        </div>
        <div className="icons-bx-main-ne mn-bvckks">
          <div>
            <b>Unlimited Queries + $3 of Compute</b>
          </div>
          <div>
            <b>10 GB of Assets</b>
          </div>

         
          <div>
            <b>API - $0.01 Per Response</b>
          </div>
          <div>
            <b>$3/GB After 10GB</b>
          </div>
        </div>

        <div className="icons-bx-main-ne mn-bvckks">
         

         
        <div>

        <b>Monthly</b>
  <b>$25</b>
  <a href="https://buy.stripe.com/7sI6px7ai8Wj71SdR5" class="glow-on-hover" type="primary">Subscribe</a>
</div>
<div>
  <b>Annual</b>
  <b>$18</b>
  <a href="https://buy.stripe.com/5kAcNV9iqegD4TKfZf" class="glow-on-hover" type="primary">Subscribe</a>
</div>
<div>
  <b>Compute</b>
  <b>${organization && organization.compute !== undefined ? organization.compute.toFixed(2) : '0.00'}</b>
  <a href="https://buy.stripe.com/6oE3dl66efkH0DuaEW" class="glow-on-hover" type="primary">Buy</a>
</div>
        </div>
       
        <div className="columns">
          {organization && organization.subscription === "Business" ? (
            <div className="action-column">
              <button className="btn btn-success">Subscribed</button>
            </div>
          ) : (
            <div className="action-column">
              <a
                href="https://buy.stripe.com/7sI6px7ai8Wj71SdR5"
                target="_blank"
              >
                <Button className="glow-on-hover" type="primary">
                  Get Started
                </Button>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
