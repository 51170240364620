import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import {
  FaUser,
  FaLock,
  FaMailBulk,
  FaMailchimp,
  FaBusinessTime,
} from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import { Form, Icon, Input, Button, Checkbox, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { v4 as uuidv4 } from "uuid";
import { MdBusiness } from "react-icons/md";
import SearchContext from "../../../Context/SearchContext";
const salt = bcrypt.genSaltSync(10);
const SignUpForm = () => {
  const { formType, setFormType, setAfterSU, user } = useContext(SearchContext);

  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    name: "",
    password: "",
    country: "country",
    permission: "owner",
    language: "English",
    business_name: "",
  });
  const [emailErr, setEmailErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [embNameErr, setBNameErr] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isEmail = testEmail();
    const isName = form.name.length > 0 ? false : true;
    const isBName = form.business_name.length > 0 ? false : true;
    const isPass = form.password.length > 7 ? false : true;
    setBNameErr(isBName);
    setNameErr(isName);
    setEmailErr(isEmail);
    setPassErr(isPass);
    if (isEmail && isName && isBName && isPass) {
      // addUser(form);
    } else {
      save();
    }
  };
  const testEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
      return false;
    } else {
      return true;
    }
  };

  const save = () => {
    const formData = new FormData();
    formData.append("business_name", form.business_name);
    formData.append("subscription", "Pro");
    formData.append("color_code", "black");
    formData.append("chat_count", 1);
    formData.append("root_email", form.email);
    formData.append("storage_usage_rate", 2.00);
    formData.append("compute_usage_rate", 2.10);
    formData.append("compute", 5.50);
    formData.append("balance", 0);
    formData.append("earn", "pending");
    formData.append("onboard", "new");

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(`https://embed.axv.ai/api/organization`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          addUser(form, response.newOrganization);
        }
      });
  };

  const addUser = (user, org) => {
    user.password = bcrypt.hashSync(
      user.password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    let id = uuidv4();
    user.profile_id = id;
    user.user_id = org._id;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(user),
    };
    fetch(`https://embed.axv.ai/api/consumer/users`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isCreated) {
          setForm({
            email: "",
            name: "",
            password: "",
            country: "country",
            organization_id: "or id",
            user_id: "",
            permission: "nothing",
            language: "English",
            business_name: "",
          });
          message.success({
            content: 'Account Created. Please Log in',
            duration: 10, // Duration in seconds
            style: {
              fontSize: '25px', // Increase font size
            },
          });
          
          // navigate("/login");
          setAfterSU(user.email);
          setFormType("login");
        } else if (response.isExists) {
          message.warning("This email is already in use");
        }
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    setForm({ ...form, [key]: val });
  };

  return (
    <Form
      name="basic"
      onSubmit={handleSubmit}
      autoComplete="off"
      className="login-form"
    >
      <h4>Get Started</h4>
      <Form.Item>
        <div className="f-div-logi0s">
          <Input
            onChange={(e) => changeField("name", e.target.value, form)}
            size="large"
            prefix={<FaUser className="input-icon-l" />}
            placeholder="Name"
            value={form.name}
            className={`${nameErr ? " err-fieldc" : ""}`}
          />
          {nameErr ? <p className="err-txt-signup">Name Required</p> : null}
        </div>
      </Form.Item>
      <Form.Item>
        <div className="f-div-logi0s">
          <Input
            onChange={(e) => changeField("business_name", e.target.value, form)}
            size="large"
            prefix={<MdBusiness className="input-icon-l" />}
            placeholder="Business Name"
            value={form.business_name}
            className={`${embNameErr ? " err-fieldc" : ""}`}
          />
          {embNameErr ? (
            <p className="err-txt-signup">Business Name Required</p>
          ) : null}
        </div>
      </Form.Item>
      <Form.Item>
        <div className="f-div-logi0s">
          <Input
            onChange={(e) =>
              changeField("email", e.target.value.toLowerCase(), form)
            }
            size="large"
            prefix={<AiFillMail className="input-icon-l" />}
            placeholder="Email"
            value={form.email}
            className={`${emailErr ? " err-fieldc" : ""}`}
          />
          {emailErr ? <p className="err-txt-signup">Need Valid Email</p> : null}
        </div>
      </Form.Item>
      <Form.Item>
        <div className="f-div-logi0s">
          <Input.Password
            onChange={(e) => changeField("password", e.target.value, form)}
            size="large"
            prefix={<FaLock className="input-icon-l" />}
            type="password"
            placeholder="Password"
            value={form.password}
            className={`${emailErr ? " err-fieldc" : ""}`}
          />
          {emailErr ? (
            <p className="err-txt-signup">
              Password Must be Atleast 8 Characters
            </p>
          ) : null}
        </div>
      </Form.Item>

      <Form.Item>
        <div className="for-reg-c">
          <div />
          Already have an account?{" "}
          <Link to="#" onClick={() => setFormType("login")}>
            Login
          </Link>
        </div>
        <div className="login-btn-c">
          <Button
            onClick={handleSubmit}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Sign Up
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default SignUpForm;
