import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import { Alert, Button, Col, Input, Row, Spin } from "antd";
import ReactPlayer from "react-player";
import moment from "moment";
import GreenTick from "../../../components/GreenTick";
import { LoadingOutlined } from "@ant-design/icons";

export default function FavoritesTab() {
  const { generate, user, setGenerate } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStore();
  }, [callApi]);

  const getStore = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/highlight?page=${
        generate.pageNo2
      }&limit=15&user_id=${user && user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        if (response && response.isSuccess) {
          setGenerate({
            ...generate,
            favorites: response.taskList,
            totalPages2: response.totalPages,
          });
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const loadMore3 = (v, key) => {
    if (
      v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
      generate.totalPages2 > generate.pageNo2 &&
      !loading
    ) {
      setGenerate({
        ...generate,
        pageNo2: generate.pageNo2 + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading(true);
      setCallApi(callApi + 1);
    }
  };
  return (
    <div>
      {loading ? <Spin /> : null}
      {generate.favorites && generate.favorites.length === 0 ? (
        <div style={{ marginTop: "10px" }} className="no-heigh-div-c">
          <Alert
            message="For accounts on the early bird plan. Contact us for early access. Fine Tuning allows you to tailor the AI generations to your specific needs"
            className="brand-alert bg-txt-a"
          />
        </div>
      ) : null}

      <div
        onScroll={(v) => {
          loadMore3(v, "pageNo3");
        }}
        className="act-logs-fcv"
      >
        {generate.favorites &&
          generate.favorites.map((im, imi) => {
            return (
              <div style={{ width: "100%" }} key={imi}>
                <div onClick={() => console.log(im)} className={`img-bx-imgsc`}>
                  {im.file_url.indexOf(".mp4") !== -1 ||
                  im.file_url.indexOf(".avi") !== -1 ||
                  im.file_url.indexOf(".mkv") !== -1 ||
                  im.file_url.indexOf(".mov") !== -1 ||
                  im.file_url.indexOf(".wmv") !== -1 ||
                  im.file_url.indexOf(".webm") !== -1 ||
                  im.file_url.indexOf(".mpeg") !== -1 ||
                  im.file_url.indexOf(".3gp") !== -1 ||
                  im.file_url.indexOf(".flv") !== -1 ? (
                    <div className="vd-nm-pr">
                      <ReactPlayer url={im.file_url} controls={false} />
                    </div>
                  ) : (
                    <div style={{ height: "60px" }}>
                      <img src={im.file_url} alt="" />
                    </div>
                  )}
                  <div className="img-bx-imgdt">
                    <div className="img-r-ast-b2">
                      <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        // onChange={(e) => {
                        //   changeField(im, "meta_data", e.target.value);
                        // }}
                        value={im.generations ? im.generations : ""}
                        // onBlur={() =>
                        //   updateTask({ meta_data: im.meta_data }, im._id)
                        // }
                        placeholder="Pending"
                      />
                    </div>

                    <i>{moment(im.createdAt).fromNow()}</i>
                  </div>
                 
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
