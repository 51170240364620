import React, { useContext } from "react";
import { Button } from 'antd';
import SearchContext from "../../../Context/SearchContext";
import { Alert, Col, Input, Row } from "antd";
import moment from "moment";
import { IoMdDownload } from 'react-icons/io';
import ReactPlayer from "react-player";
import { FaArrowRight,FaFileCsv,FaFilePdf } from "react-icons/fa";
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';


export default function GenerateTab() {
  const {
    images,
    setImages,
    pActive,
    setassist,
    assist,
    right,
    setRight,
    generate,
    user,
    type,
    setType,
  } = useContext(SearchContext);

   {/*const generateCSVData = () => {
    // Create the CSV data
  //  const csvData = generate.data.map((im) => {
  //    const fileUrl = im.file_url;
      const generations = im.generations?.join(';') || '';
      return {
        'File URL': fileUrl,
        'Generations': generations,
      };
    });

    return csvData;
  };*/}

  



  
  return (
    <div className="act-logs-fcv">
      {/* <CSVLink data={generateCSVData()} filename="generated_data.csv">
        <div style={{ color: 'green', cursor: 'pointer' }}>
          <FaFileCsv size={30} />
        </div>
      </CSVLink>*/}
      
    
      {generate.data && generate.data.length === 0 ? (
        <div
          onClick={() => console.log(generate)}
          style={{ marginTop: "10px" }}
          className="no-heigh-div-c"
        >
          <Alert
            message="Clips could not be generated. Please try again later."
            className="brand-alert bg-txt-a"
          />
        </div>
      ) : null}
      {generate.clips && generate.clips.length === 0 ? (
        <div style={{ marginTop: "10px" }} className="no-heigh-div-c">
          <Alert
            message="Clips could not be generated. Please try again later."
            className="brand-alert bg-txt-a"
          />
        </div>
      ) : null}

      <Row>
        {generate.clips &&
          generate.clips.map((im, imi) => {
            return (
              <Col key={imi} lg={{ span: 6 }} md={{ span: 6 }}>
                <div className={`img-bx-imgsc img-gen-clips`}>
                  <div style={{ height: "60px" }}>
                    <img src={im.file_url} alt="" />
                  </div>
                  <div className="img-bx-imgdt">
                    <i>{im.startTime}</i>
                  </div>
                </div>
              </Col>
            );
          })}

        {generate.data &&
          generate.data.map((im, imi) => {
            return (
              <Col style={{ width: "100%" }} key={imi} lg={{ span: 24 }}>
                <div onClick={() => console.log(im)} className={`img-bx-imgsc`}>
                  {im.file_url.indexOf(".mp4") !== -1 ||
                  im.file_url.indexOf(".avi") !== -1 ||
                  im.file_url.indexOf(".mkv") !== -1 ||
                  im.file_url.indexOf(".mov") !== -1 ||
                  im.file_url.indexOf(".wmv") !== -1 ||
                  im.file_url.indexOf(".webm") !== -1 ||
                  im.file_url.indexOf(".mpeg") !== -1 ||
                  im.file_url.indexOf(".3gp") !== -1 ||
                  im.file_url.indexOf(".flv") !== -1 ? (
                    <div className="vd-nm-pr">
                      <ReactPlayer url={im.file_url} controls={false} />
                    </div>
                  ) : (
                    <div style={{ height: "60px" }}>
                      <img src={im.file_url} alt="" />
                    </div>
                  )}
                  <div className="img-bx-imgdt">
                    <div className="img-r-ast-b2">
                      <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 25 }}
                        // onChange={(e) => {
                        //   changeField(im, "meta_data", e.target.value);
                        // }}
                        value={im.generations?.length ? im.generations[0] : ""}
                        // onBlur={() =>
                        //   updateTask({ meta_data: im.meta_data }, im._id)
                        // }
                        placeholder="Embedding"
                      />
                    </div>

                    {/* <i>{moment(im.createdAt).fromNow()}</i> */}
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
      <Row>
      {generate.imagesGens &&
  generate.imagesGens.map((im, imi) => {
    console.log('Image:', im);
    return (
      <Row key={imi}>
        <Col lg={{ span: 11 }} md={{ span: 11 }}>
          <div className={`img-bx-imgsc img-gen-clips imgs-tb-img`}>
            <img src={im.file_url} alt="" />
          </div>
        </Col>
        <Col lg={{ span: 2 }} md={{ span: 2 }}>
          <div className="righ-arr-gen-img">
            <FaArrowRight />
          </div>
        </Col>
        <Col lg={{ span: 11 }} md={{ span: 11 }}>
        <div className={`img-bx-imgsc img-gen-clips imgs-tb-img`}>
      {im.generated ? (
        <div className="generated-image-container">
          <img src={im.generated} alt="" />
          <a href={im.generated} download="generated_image.png" className="download-button">
            <IoMdDownload />
          </a>
        </div>
            ) : (
              <b>Image Failed</b>
            )}
          </div>
        </Col>
      </Row>
    );
  })}


</Row>
      </div>
  );


}
