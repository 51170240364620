import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Dropdown, Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { RiAccountBoxFill, RiAccountCircleFill } from "react-icons/ri";
import { FaDollarSign } from "react-icons/fa";
import RoamTime from "./RoamTime";
import SearchContext from "../Context/SearchContext";
import NormalLoginForm from "./login/components/LoginForm";
import ForgetPassword from "./login/components/ForgetPassword";
import SignUpForm from "./login/components/SignupForm";
import { TiTick } from "react-icons/ti";
import MyAccount from "./account/components/MyAccount";
import MyOrganization from "./account/components/MyOrganization";
import MyTeam from "./team/MyTeam";
import Pricing from "./Pricing";
export default function HandleLogin({ isIndex }) {
  const {
    formType,
    setFormType,
    user,
    setUser,
    resetAll,
    setDisplay,
    setOrganization,
    organization,
    display,
    setassist,
    assist,
    theme,
    language,
    setLanguage,
  } = useContext(SearchContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      setUser(u);
    }
    setLoading(false);
  }, []);
  const items = [
    {
      key: "2",
      label: <span onClick={() => logout()}>Log Out</span>,
    },
  ];

  const logout = () => {
    window.localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
    setUser();
    setOrganization();
    resetAll();
    setFormType(); // Reload the page
    //navigate("/");
  };

  const handleDocsRedirect = () => {
    window.open("https://docs.axv.ai", "_blank");
  };

  const changeLanguage = (v) => {
    window.localStorage.setItem("language", v);
    setLanguage(v);
  };

  return loading ? null : (
    <div className={`bx-r-mr-cs hdr-tps ${isIndex ? " hm-top-r" : ""} `}>
      {/* <Select
        defaultValue={language}
        value={language}
        style={{
          width: 90,
        }}
        onChange={changeLanguage}
        options={[
          {
            value: "english",
            label: "English",
          },
          {
            value: "french",
            label: "French",
          },
          {
            value: "spanish",
            label: "Spanish",
          },
          {
            value: "chinese",
            label: "Chinese",
          },
          {
            value: "arabic",
            label: "Arabic",
          },
          {
            value: "hindi",
            label: "Hindi",
          },
          {
            value: "portuguese",
            label: "Portuguese",
          },
          {
            value: "russian",
            label: "Russian",
          },
          {
            value: "japanese",
            label: "Japanese",
          },
          {
            value: "german",
            label: "German",
          },
          {
            value: "korean",
            label: "Korean",
          },
          {
            value: "italian",
            label: "Italian",
          },
          {
            value: "turkish",
            label: "Turkish",
          },
          {
            value: "dutch",
            label: "Dutch",
          },
          {
            value: "swedish",
            label: "Swedish",
          },
          {
            value: "polish",
            label: "Polish",
          },
        ]}
        
      /> */}
      {user && user.name ? (
        <div className="roadm-t-div">
          {organization ? (
            <>
              <a href="/" rel="noopener noreferrer">
                <button
                  className="custom-button "
                  style={{ marginRight: "10px" }}
                >
                  {" "}
                  Console{" "}
                </button>
              </a>

              <a
                href="https://docs.olm.ai"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: "10px" }}
              >
                <button className="custom-button "> Docs </button>
              </a>

              {/*
<div
  onClick={() =>
    setassist({
      ...assist,
      isUpgrade: true,
    })
  }
  className="compute-txt-bcs btn-appr signop-btn"
>
  Compute: {organization.compute.toFixed(2)}
</div>
*/}
            </>
          ) : null}
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomLeft"
          >
            <span className="btn-appr signop-btn">{user.name}</span>
          </Dropdown>
        </div>
      ) : (
        <>
          <span
            onClick={() => {
              navigate("/login");
            }}
            style={{  marginRight: "10px" }}
            className="custom-button "
          >
            Login
          </span>
          <span
            onClick={handleDocsRedirect} // Use the new handleDocsRedirect function
            className="custom-button signop-btn hide-mobile"
            style={{ marginRight: "10px" }}
          >
            Docs
          </span>
        </>
      )}
      <Modal
        width={400}
        className={`no-h-bo-b-modal brand-modal2 brand-modal chat-mmmd ${theme}-theme`}
        open={formType !== undefined}
        onCancel={() => setFormType()}
      >
        <div className="login-form-btnc">
          {formType === "login" ? (
            <NormalLoginForm />
          ) : formType === "forget" ? (
            <ForgetPassword />
          ) : formType === "signup" ? (
            <SignUpForm />
          ) : null}
        </div>
      </Modal>
      <Modal
        className={`no-h-bo-b-modal top-20-x brand-modal white-bg-modal ${theme}-theme`}
        open={display !== undefined}
        onCancel={() => setDisplay()}
        width={900}
      >
        <h5 className="create-btn-h">Hi {user && user.name}</h5>

        <div className="bx-form-brand">
          <div style={{ marginBottom: "10px" }} className="bx-main-sel-prf">
            <span
              onClick={() => setDisplay("account")}
              className={`btn-appr select-tcbn-a ${
                display === "account" ? " btn-apr-s" : ""
              }`}
            >
              <span>Account {display === "account" ? <TiTick /> : ""}</span>
            </span>
            <span
              onClick={() => setDisplay("Organization")}
              className={`btn-appr select-tcbn-a ${
                display === "Organization" ? " btn-apr-s" : ""
              }`}
            >
              <span>
                Organization {display === "Organization" ? <TiTick /> : ""}
              </span>
            </span>
            <span
              onClick={() => setDisplay("Team")}
              className={`btn-appr select-tcbn-a ${
                display === "Team" ? " btn-apr-s" : ""
              }`}
            >
              <span>Team {display === "Team" ? <TiTick /> : ""}</span>
            </span>
            <span
              onClick={() => setDisplay("Billing")}
              className={`btn-appr select-tcbn-a ${
                display === "Billing" ? " btn-apr-s" : ""
              }`}
            >
              <span>Billing {display === "Billing" ? <TiTick /> : ""}</span>
            </span>
            <span
              onClick={() => setDisplay("Pricing")}
              className={`btn-appr select-tcbn-a ${
                display === "Pricing" ? " btn-apr-s" : ""
              }`}
            >
              <span>Pricing {display === "Pricing" ? <TiTick /> : ""}</span>
            </span>
          </div>
          {display === "account" ? <MyAccount /> : null}
          {display === "Organization" ? <MyOrganization /> : null}
          {display === "Team" ? <MyTeam /> : null}
          {display === "Pricing" ? <Pricing /> : null}
          {display === "Billing" ? (
            <div className="bx-cls-bmbs">
              <div
                className="no-heigh-div-c full-h-bx-a"
                style={{
                  width: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://billing.stripe.com/p/login/00gfZz7uLeaH50Y288"
                  target="_blank"
                >
                  <Alert
                    message="Billing"
                    description={
                      <div>
                        <div>
                          Click here to access the billing portal. Securely
                          managed by Stripe. You can manage your account's
                          billing and payment information, change your payment
                          method or billing address.
                        </div>
                      </div>
                    }
                    type="info"
                    showIcon
                    className="brand-alert bg-txt-a"
                  />
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
}
