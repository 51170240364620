import React, { useContext } from "react";
import SearchContext from "../../Context/SearchContext";
import Onboard from "../newHome/components/onboard/Onboard";
import Header from "../../components/Header";
import "../newHome/newhome.css";
import { Col, Row } from "antd";
import { BsDatabaseCheck, BsFileCode } from "react-icons/bs";
import {
  MdMusicVideo,
  MdOutlineImageSearch,
  MdSlowMotionVideo,
  MdSwitchAccount,
} from "react-icons/md";
import { RiImageEditFill } from "react-icons/ri";
import { BiBrain } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function AfterLogin() {
  const {
    setUser,
    loginChange,
    left,
    setLeft,
    theme,
    setTheme,
    setOrganization,
    setLanguage,
    setDisplay,
  } = useContext(SearchContext);
  const navigate = useNavigate();
  const openPage = (r) => {
    window.open(r, "_blank");
  };
  return (
    <div className={`mn-bvcio main-bx-2fc-m ${theme}-theme`} style={{ fontFamily: 'Inter, sans-serif' }}>
      <Header />
      <Row>
    
        <Col span={24}>
        
        <div className="console-cards-bx-m">
   
            <div class="cave-card" onClick={() => navigate("/cave")}>
              <div>
                <h3>Cave</h3>
                <p>
                  Playground to see how Olms work and leverage it’s visual
                  capabilities for your business.
                </p>
              </div>
              <div>
                <BsDatabaseCheck className="black-icon"/>
              </div>
            </div>
            <div className="cave-card" onClick={() => openPage("https://docs.olm.ai/developers/completion")}>
              <div>
                <h3>Completion</h3>
                <p>
                  A single API endpoint for any visual task. Simplify workflows
                  in minutes.
                </p>
              </div>{" "}
              <div>
                <MdSlowMotionVideo />
                <MdOutlineImageSearch />
                <RiImageEditFill />
                <MdMusicVideo />
              </div>
            </div>
            <div className="cave-card" >
              <div>
                <h3>Embedding</h3>
                <p>
                  Coming Soon. Build AI applications that understand images and videos in a
                  whole new way.
                </p>
              </div>{" "}
              <div>
                <BiBrain />
              </div>
            </div>
            <div className="cave-card" onClick={() => openPage("https://docs.olm.ai/product/getting-started")}>
              <div>
                <h3>Product</h3>
                <p>Learn about the visual capabilities of Olms</p>
              </div>{" "}
              <div>
                <BiBrain />
              </div>
            </div>
            <div className="cave-card" onClick={() => openPage("https://docs.olm.ai/developers/getting-started")}>
              <div>
                <h3>Developers</h3>
                <p>Documentation for programmatic visual applications.</p>
              </div>{" "}
              <div>
                <BsFileCode />
              </div>
            </div>
            <div className="cave-card" onClick={() => setDisplay("account")}>
              <div>
                <h3>Account</h3>
                <p>
                  Manage account details, team members, subscriptions, usage,
                  api keys and much more.
                </p>
              </div>{" "}
              <div>
                <MdSwitchAccount />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div></div>
    </div>
  );
}
