import React, { useState } from "react";
import SearchContext from "./SearchContext";

const SearchState = (props) => {
  const [chat, setChat] = useState(1);
  const [images, setImages] = useState({ pageNo: 1, pageNoV: 1 });
  const [assist, setassist] = useState({
    pageNo: 1,
    activeTab: "Guide",
    demo_id: "store_demo",
  });
  const [user, setUser] = useState();
  const [right, setRight] = useState({});
  const [theme, setTheme] = useState("black");
  const [left, setLeft] = useState({});
  const [generate, setGenerate] = useState({
    pageNo1: 1,
    pageNo2: 1,
    pageNo3: 1,
  });
  const [load, setLoad] = useState();
  const [incident, setincident] = useState();
  const [loginChange, setLoginChange] = useState(false);
  const [organization, setOrganization] = useState();
  const [formType, setFormType] = useState();
  const [afterSU, setAfterSU] = useState();
  const [loading, setLoading] = useState(true);
  const [callApi, setCallApi] = useState(1);
  const [display, setDisplay] = useState();
  const [profile, setProfile] = useState({ pageNo: 1 });
  const [pActive, setPActive] = useState();
  const [type, setType] = useState("image");
  const [callOrg, setCallOrg] = useState(1);
  const [language, setLanguage] = useState("english");

  const resetAll = () => {
    setImages({ pageNo: 1, pageNoV: 1 });
    setLoginChange(loginChange + 1);
    setGenerate({ pageNo1: 1, pageNo2: 1, pageNo3: 1 });
    setRight({});
    setChat({
      feed: chat.feed,
      pageNo: 1,
      activeTab: "Guide",
      demo_id: "store_demo",
    });
    setassist({
      pageNo: 1,
      activeTab: "Guide",
      demo_id: "store_demo",
    });
  };
  return (
    <SearchContext.Provider
      value={{
        language,
        setLanguage,
        left,
        setLeft,
        theme,
        setTheme,
        setCallOrg,
        callOrg,
        type,
        setType,
        generate,
        setGenerate,
        pActive,
        setPActive,
        profile,
        setProfile,
        display,
        setDisplay,
        callApi,
        setCallApi,
        afterSU,
        setAfterSU,
        loading,
        setLoading,
        formType,
        setFormType,
        resetAll,
        loginChange,
        setLoginChange,
        organization,
        setOrganization,
        incident,
        setincident,

        load,
        setLoad,
        right,
        setRight,
        user,
        setUser,
        assist,
        setassist,
        images,
        setImages,
        chat,
        setChat,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
export default SearchState;
