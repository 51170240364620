import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/SearchContext";
import UploadAssets from "./components/UploadAssets";
import { Col, Drawer, Form, Input, Row, Spin, message } from "antd";
import Chat from "./components/Chat";
import Assests from "./components/Assests";
import { AiOutlineClose } from "react-icons/ai";
import { RiSendPlaneFill } from "react-icons/ri";
import ReactPlayer from "react-player";
import { BsArrowDownShort } from "react-icons/bs";
import RightsCards from "./components/RightsCards";
import Generate from "./components/Generate";

export default function Home() {
  const {
    assist,
    setassist,
    profile,
    setOrganization,
    organization,
    callOrg,
    load,
    setLoad,
    user,
    chatId,
    pActive,
    right,
  } = useContext(SearchContext);
  const [comentNew, setComentNew] = useState("");

  const handleChange = (e) => {
    setComentNew(e.target.value);
  };
  const findTimeStamp = (q) => {
    let a = q;
    const i = a.indexOf(":");
    if (i !== -1 && a.slice(i + 3, i + 4) === ":") {
      return a.slice(i - 2, i + 6);
    }
  };

  useEffect(() => {
    if (user && user.id) {
      getOrganization();
    }
  }, [user, callOrg]);

  const getOrganization = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user && user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          setOrganization(response.organization);
        }
      });
  };

  const handleSubmit = (p) => {
    // if (comentNew === "") {
    //   return;
    // }
    const c = p ? p : comentNew;
    const txt = c.trim().length ? c.trim() : assist.upImage ? "Image Sent" : "";
    setLoad(txt);
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const obj = {
      query: txt,
      user_id: user ? user.user_id : "mapp",
      assistant_id: pActive ? pActive._id : "645f00b8744d5ff6bc8571e8",
      video_id: assist.selAssetID2 ? assist.selAssetID2._id : undefined,
      startTime: assist.selAssetID2 ? findTimeStamp(txt) : undefined,
      image_id: assist.upImage
        ? assist.upImage[0].purpose === "misc"
          ? assist.upImage[0]._id
          : assist.upImage[0].headclip_id
        : assist.selAssetID
        ? assist.selAssetID.purpose === "misc"
          ? assist.selAssetID._id
          : assist.selAssetID.headclip_id
        : undefined,
      media: assist.selAssetID2
        ? true
        : assist.upImage
        ? true
        : assist.selAssetID
        ? true
        : assist.chatMedia
        ? true
        : undefined,
    };
    setComentNew("");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/v3/chapter/chat`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoad();
        if (data && data.isSuccess) {
          const all = assist.data ? assist.data : [];
          let d = data.data;
          d.response = data.response ? data.response : undefined;
          d.is_approval = data.is_approval ? data.is_approval : undefined;
          d.isProduct = data.isProduct ? data.isProduct : false;
          d._id = data.chat_id ? data.chat_id : undefined;

          all.push(d);
          setassist({
            ...assist,
            idClip: chatId ? data.data : undefined,
            data: all,
            selAssetID: undefined,
          });
        } else {
          if (
            data.message ===
            "Chat limit has been reached, please add subscription"
          ) {
            // setFormType("signup");
          } else {
            message.error("Something weng wrong");
          }
        }
      });
  };
  return (assist.data && assist.data.length) ||
    (profile.data && profile.data.length) ? (
    <div className="home-pg-mcsv">
      <Row className="row-main-b-h">
        <Col
          className="hide-mobile"
          lg={{ span: 11 }}
          md={{ span: 11 }}
          sm={{ span: 24 }}
        >
          <div style={{ paddingRight: "15px" }} className="col-inner-hm-cs">
            <Assests />
          </div>
        </Col>
        <Col lg={{ span: 9 }} md={{ span: 9 }} sm={{ span: 24 }}>
          <div className="col-inner-hm-cs">
            {right.activeRight === "Generate" ? <Generate /> : <Chat />}
          </div>
        </Col>
        <Col
          className="hide-mobile"
          lg={{ span: 4 }}
          md={{ span: 4 }}
          sm={{ span: 24 }}
        >
          <div style={{ paddingLeft: "15px" }} className="col-inner-hm-cs">
            <RightsCards />
          </div>
        </Col>
      </Row>
      <Drawer
        title="Media"
        placement="right"
        width={300}
        onClose={() => setassist({ ...assist, isMedia: false })}
        open={assist.isMedia}
      >
        <Assests />
      </Drawer>
    </div>
  ) : (
    <div className="home-pg-mcsv">
      <h1 style={{ marginBottom: "0px" }}>
        Enter the <span style={{ color: "#9c28ff" }}>AxV </span> Studio
      </h1>
      <p style={{ marginBottom: "5px" }}>
        Experience AxV, an AI model that improves visual
        accessibility and can perform actions from your visual data. Trying for the first time? Upload below.
        
      </p>

      {assist.selAssetID || assist.selAssetID2 ? (
        <div className="aftr-up-mbics">
          {assist.selAssetID ? (
            <div className="sel-img-id-ass">
              <img className="" src={assist.selAssetID.file_url} alt="" />
              <AiOutlineClose
                style={{ color: "white" }}
                onClick={() =>
                  setassist({
                    ...assist,

                    selAssetID: undefined,
                  })
                }
              />
            </div>
          ) : (
            <div className="sel-img-id-ass">
              <div className="vd-nm-pr">
                <ReactPlayer
                  url={assist.selAssetID2.file_url}
                  controls={false}
                />
              </div>
              <AiOutlineClose
                onClick={() =>
                  setassist({
                    ...assist,

                    selAssetID2: undefined,
                  })
                }
              />
            </div>
          )}

          <div className="bottom-chat-c">
            <div className="main-com-bx-1">
              <Form
                onFinish={() => {
                  handleSubmit();
                }}
                style={{ marginBottom: "0px", width: "100%" }}
              >
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  className="create-cmt-vdc"
                >
                  <Input
                    style={{ marginBottom: "0px" }}
                    placeholder="Discuss or Ask Anything"
                    onChange={handleChange}
                    value={comentNew}
                    size="large"
                  />
                  <div className="icns-icns-st-rt">
                    {load ? (
                      <Spin />
                    ) : (
                      <RiSendPlaneFill
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    )}
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        <UploadAssets isHome={true} />
      )}
    </div>
  );
}
