import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Input,
  Progress,
  Row,
  Spin,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { MdClose, MdKeyboardBackspace } from "react-icons/md";
import Paragraph from "antd/lib/typography/Paragraph";
import UploadAssets from "../UploadAssets";
import SearchContext from "../../../../Context/SearchContext";
import { FaMinus, FaPlay, FaUpload } from "react-icons/fa";
import { TiTick } from "react-icons/ti";

export default function Assests({ active, setActive }) {
  const {
    images,
    setImages,
    theme,
    setassist,
    assist,
    right,
    setRight,
    generate,
    user,
    type,
    setType,
    setGenerate,
  } = useContext(SearchContext);
  const playerRef = React.useRef();

  const [loading2, setLoading2] = useState(true);
  const [callApi, setCallApi] = useState(1);
  const [callApi2, setCallApi2] = useState(1);
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(false);
  const [play, setPlay] = useState();
  const [act, setAct] = useState("");
  const [loadingP, setLoadingP] = useState(true);
  const [isGen, setIsGen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadingP(false);
    }, 500);
  }, []);
  useEffect(() => {
    if (images.pageNo !== 1) {
      getImages();
    }
    if (images.data && images.pageNo === 1) {
      setLoading2(false);
    }
  }, [callApi]);

  useEffect(() => {
    if (images.pageNoV !== 1) {
      getVideos();
    }
  }, [callApi2]);

  useEffect(() => {
    if (type !== "video" && images.data === undefined) {
      getImages();
    }
    if (type === "video" && images.videos === undefined) {
      getVideos();
    }
  }, [type, isGen, refresh]);

  const getVideos = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${
        images.pageNoV
      }&limit=15&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=original&status=${isGen ? "generated" : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          setImages({
            ...images,
            videos: images.videos
              ? images.videos.concat(data.imageList)
              : [].concat(data.imageList),
            totalPages2: data.totalPages,
          });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const getImages = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${
        images.pageNo
      }&limit=8&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=visual&status=${isGen ? "generated" : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          setImages({
            ...images,
            data: images.data
              ? images.data.concat(data.imageList)
              : [].concat(data.imageList),
            totalPages: data.totalPages,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };

  const loadMore = (v, key) => {
    if (
      v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
      images.totalPages > images.pageNo &&
      !loading2
    ) {
      setImages({
        ...images,
        pageNo: images.pageNo + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi(callApi + 1);
    }
  };

  const loadMore2 = (v, key) => {
    if (
      v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
      images.totalPages2 > images.pageNoV &&
      !loading2
    ) {
      setImages({
        ...images,
        pageNoV: images.pageNoV + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi2(callApi2 + 1);
    }
  };
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = images[key].filter((p) => p._id !== row._id);
          setImages({ ...images, [key]: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const adjustClick = (im, key1, key2) => {
    if (
      right.activeRight !== "Generate" ||
      generate.activeType === "custom_image"
    ) {
      console.log("1" , im)
      setassist({
        ...assist,
        selAssetID: im,
        // upImage: undefined,
        // selAssetID2: undefined,
      });

      setRight({
        ...right,
        activeAI: "message",
        activeIcon: undefined,
      });
    } else {
      const all = generate.selectedIMG ? [...generate.selectedIMG] : [];
      const find = all.filter((f) => f._id === im._id);

      if (find.length) {
        setGenerate({
          ...generate,
          selectedIMG: all.filter((f) => f._id !== im._id),
          selectedVID: undefined,
        });
      } else {
        setGenerate({
          ...generate,
          selectedIMG: [...all, im],
          selectedVID: undefined,
        });
      }
    }
  };

  const adjustClickV = (im, key1) => {
    if (
      right.activeRight !== "Generate" ||
      generate.activeType === "clips" ||
      generate.activeType === "custom_image"
    ) {
      setassist({
        ...assist,
        selAssetID2: im,
        selAssetID: undefined,
        upImage: undefined,
      });
    } else {
      const all = generate.selectedVID ? [...generate.selectedVID] : [];
      const find = all.filter((f) => f._id === im._id);

      if (find.length) {
        setGenerate({
          ...generate,
          selectedVID: all.filter((f) => f._id !== im._id),
          selectedIMG: undefined,
        });
      } else {
        setGenerate({
          ...generate,
          selectedVID: [...all, im],
          selectedIMG: undefined,
        });
      }
    }
  };

  return loadingP ? null : (
    <div
      className={` chat-bx-mn  gradient-bg0c right-assist-c`}
      // onClick={() => console.log(generate)}
    >
      <div className="activity-log-main-c guide-main-chat-r">
        <div className="act-logs-fcvhjg">
          <div className="bx-main-sel-prf">
            <span
              onClick={() => setType("image")}
              className={`btn-appr select-tcbn-a ${
                type === "image" ? " btn-apr-s" : ""
              }`}
            >
              <span>Images</span>
            </span>
            <span
              onClick={() => setType("video")}
              className={`btn-appr select-tcbn-a ${
                type === "video" ? " btn-apr-s" : ""
              }`}
            >
              <span>Videos</span>
            </span>
          </div>
        </div>
        <div className="gen-img-btn-cs">
          <Button
                      type="primary"

            onClick={() => {
              setImages({
                ...images,
                pageNo: 1,
                pageNoV: 1,
                data: undefined,
                videos: undefined,
              });
              setLoading2(true);
              setRefresh(refresh + 1);
              
            }}
            style={{marginLeft:"10px"}}
          
          >
            <span>Refresh</span>
          </Button>
          <Button
            type={!isGen ? "primary": "default"}
            onClick={() => {
              setImages({
                ...images,
                pageNo: 1,
                pageNoV: 1,
                data: undefined,
                videos: undefined,
              });
              setLoading2(true);
              setIsGen(!isGen);
            }}
            style={{marginRight:"10px"}}
            className={` ${
              isGen ? " btn-apr-s" : ""
            }`}
          >
            <span>Generated {isGen ? <TiTick className="ytik-iocn" /> : ""}</span>
          </Button>
        </div>

        {type === "image" ? (
          <div
            onScroll={(v) => {
              loadMore(v, "pageNo");
            }}
            className="act-logs-fcv"
          >
            <Row>
              {images.data &&
                images.data.map((im, imi) => {
                  return (
                    <Col
                      key={imi}
                      lg={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 16 }}
                    >
                      <div
                        className={`img-bx-imgsc ${
                          (assist.selAssetID &&
                            im._id === assist.selAssetID._id &&
                            (right.activeRight !== "Generate" ||
                              generate.activeType === "custom_image")) ||
                          (right.activeRight === "Generate" &&
                            generate.selectedIMG &&
                            generate.activeType !== "custom_image" &&
                            generate.selectedIMG.filter((f) => f._id === im._id)
                              .length)
                            ? " sel-asset-c"
                            : ""
                        }`}
                      >
                        <div className="img-bx-mnb">
                          <img
                            onClick={() => {
                              adjustClick(im, "selectedIMG", "selectedVID");
                            }}
                            src={im.file_url}
                            alt=""
                          />
                        </div>

                        <div className="close-icn-asst-c">
                          <Popconfirm
                            title="Delete Image"
                            description="Are you sure you to delete this image?"
                            onConfirm={() => deleteTask(im, "data")}
                            okText="Yes"
                            cancelText="No"
                          >
                            <MdClose />
                          </Popconfirm>
                        </div>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {images.data && images.data.length === 0 ? (
              <div className="no-heigh-div-c ">
                <Alert
                  message="No Assets Exist"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-a"
                />
              </div>
            ) : null}
            {loading2 ? <Spin /> : null}
          </div>
        ) : null}

        {type === "video" ? (
          <div
            onScroll={(v) => {
              loadMore2(v, "pageNoV");
            }}
            className="act-logs-fcv"
          >
            {/* {act ? null : (
              <Button
                onClick={() => {
                  setLoading2(true);
                  setImages({
                    ...images,
                    videos: undefined,
                    totalPages2: undefined,
                  });
                  setCallApi(callApi + 1);
                }}
              >
                Refresh
              </Button>
            )} */}
            {images.videos && images.videos.length === 0 ? (
              <div className="no-heigh-div-c ">
                <Alert
                  message="No Assets Exist"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-a"
                />
              </div>
            ) : null}
            {images.videoImages ? (
              <div>
                <MdKeyboardBackspace
                  className="back-icon-csf"
                  onClick={() => {
                    setAct("");
                    setImages({
                      ...images,
                      videoImages: undefined,
                    });
                  }}
                />
              </div>
            ) : null}
            <Row>
              {images.videos &&
                images.videos
                  .filter((f) => f.file_url.includes(act))
                  .map((im, imi) => {
                    return (
                      <Col
                        key={imi}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 2 }}
                      >
                        <div
                          key={imi}
                          className={`img-bx-imgsc vd-bx-nn ${
                            (assist.selAssetID2 &&
                              im._id === assist.selAssetID2._id &&
                              (right.activeRight !== "Generate" ||
                                generate.activeType === "clips" ||
                                generate.activeType === "custom_image")) ||
                            (right.activeRight === "Generate" &&
                              generate.activeType !== "clips" &&
                              generate.activeType !== "custom_image" &&
                              generate.selectedVID &&
                              generate.selectedVID.filter(
                                (f) => f._id === im._id
                              ).length)
                              ? " sel-asset-c"
                              : ""
                          }`}
                        >
                          <div
                            onClick={() => {
                              adjustClickV(im, generate.selectedVID);
                            }}
                            className="vd-nm-pr"
                          >
                            <ReactPlayer url={im.file_url} controls={false} />
                          </div>
                          <div className="img-bx-imgdt">
                            <div className="img-r-ast-b2">
                              <b>
                                <Paragraph
                                  ellipsis={{
                                    rows: 2,
                                    // expandable: true,
                                    symbol: "see more",
                                  }}
                                  className="desc-p-crdjjkl"
                                >
                                  {im.meta_data}
                                </Paragraph>
                              </b>
                              <div className="icons-bx-ri-vd">
                                <FaPlay
                                  onClick={() => setPlay(im)}
                                  style={{ marginRight: "px" }}
                                  className="r-atc-incs"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="close-icn-asst-c">
                            <Popconfirm
                              title="Delete Video"
                              description="Are you sure to delete this video?"
                              onConfirm={() => deleteTask(im, "videos")}
                              okText="Yes"
                              cancelText="No"
                            >
                              <MdClose />
                            </Popconfirm>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
            </Row>

            {images.videoImages &&
              images.videoImages.map((im, imi) => {
                return (
                  <div key={imi} className={`img-bx-imgsc`}>
                    <div>
                      <img src={im.file_url} alt="" />
                    </div>
                    <div className="img-bx-imgdt">
                      <div className="img-r-ast-b2">
                        <FaPlay
                          onClick={() => setPlay(act)}
                          style={{ marginRight: "10px" }}
                          className="r-atc-incs"
                        />
                        {/* <p>{im.summary}</p> */}
                      </div>

                      <i>{im.startTime}</i>
                    </div>
                  </div>
                );
              })}

            {loading2 ? <Spin /> : null}
          </div>
        ) : null}

        <Modal
          className={`no-h-bo-b-modal top-20-x brand-modal ${theme}-theme`}
          open={play !== undefined}
          onCancel={() => setPlay()}
          width={600}
        >
          {play ? (
            <div className="video-top-right video-proper-v">
              <ReactPlayer
                url={act ? act : play.file_url}
                controls
                ref={playerRef}
                playing={play !== undefined}
              />
            </div>
          ) : null}
        </Modal>
      </div>
    </div>
  );
}
