import React, { useContext } from "react";
import SearchContext from "../../../Context/SearchContext";
import { BsBarChartLine, BsChatRightQuote } from "react-icons/bs";
import { AiFillHeart, AiOutlineSearch } from "react-icons/ai";
import { SiBuildkite } from "react-icons/si";
import { MdStyle, MdTimelapse } from "react-icons/md";
export default function RightsCards() {
  const { right, setRight, setType } = useContext(SearchContext);
  return (
    <div className="right-bx-mn-crd chat-bx-mn">
      <div className="bx-main-sel-prf">
        <span
          onClick={() => setRight({ ...right, activeRight: "Chat" })}
          className={`btn-appr select-tcbn-a ${
            right.activeRight === undefined || right.activeRight === "Chat"
              ? " btn-apr-s"
              : ""
          }`}
        >
          <BsChatRightQuote />
          <span>Chat</span>
        </span>
        <span
          onClick={() => setRight({ ...right, activeRight: "Search" })}
          className={`btn-appr select-tcbn-a ${
            right.activeRight === "Search" ? " btn-apr-s" : ""
          }`}
        >
          <AiOutlineSearch />
          <span>Search & Analytics</span>
        </span>

        {/*<span
  onClick={() => setRight({ ...right, activeRight: "Analytics" })}
  className={`btn-appr select-tcbn-a ${
    right.activeRight === "Analytics" ? " btn-apr-s" : ""
  }`}
>
  <BsBarChartLine />
  <span>Analytics</span>
</span>*/}
        <span
          onClick={() => setRight({ ...right, activeRight: "Generate" })}
          className={`btn-appr select-tcbn-a ${
            right.activeRight === "Generate" ? " btn-apr-s" : ""
          }`}
        >
        
          <SiBuildkite />
          <span>Generate</span>
        </span>
        <span
          onClick={() => {
            setRight({ ...right, activeRight: "Async" });
            setType("Async");
          }}
          className={`btn-appr select-tcbn-a ${
            right.activeRight === "Async" ? " btn-apr-s" : ""
          }`}
        >
          <MdTimelapse />
          <span>Async</span>
        </span>
       {/* <span
          onClick={() => {
            setRight({ ...right, activeRight: "Favorites" });
            setType("Favorites");
          }}
          className={`btn-appr select-tcbn-a ${
            right.activeRight === "Favorites" ? " btn-apr-s" : ""
          }`}
        >
          <AiFillHeart />
          <span>Favorites</span>
        </span>*/}
      {/*  <span
          onClick={() => {
            setRight({ ...right, activeRight: "Styles" });
            setType("Styles");
          }}
          className={`btn-appr select-tcbn-a ${
            right.activeRight === "Styles" ? " btn-apr-s" : ""
          }`}
        >
          <MdStyle />
          <span>Styles</span>
        </span>*/}
      </div>
    </div>
  );
}
