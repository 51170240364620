import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Alert } from "antd";
import SearchContext from "../../../../Context/SearchContext";

export default function SearchThubnail() {
  const { left } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return loading ? null : (
    <div className={` chat-bx-mn `}>
      <div className="act-logs-fcv">
        <Row>
          {left.chatData?.clip_data &&
            left.chatData.clip_data.map((im, imi) => {
              return (
                <Col
                  key={imi}
                  lg={{ span: 8 }}
                  md={{ span: 8 }}
                  sm={{ span: 16 }}
                >
                  <div className={`img-bx-imgsc `}>
                    <div className="img-bx-mnb">
                      <div>
                        <img src={im.file_url} alt="" />
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
        {left.chatData?.clip_data && left.chatData.clip_data.length === 0 ? (
          <div className="no-heigh-div-c ">
            <Alert
              message="No Assets Exist"
              type="info"
              showIcon
              className="brand-alert bg-txt-a"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
